.feedback {
  font-size: 1rem;

  &-input-error,
  &-input-help {
    position: absolute;
    right: 0.3rem;
    bottom: -1.6rem;
  }

  &-input-error,
  &-error,
  &-form-error {
    font-weight: 500;
    color: #a6192e;
  }

  &-input-help {
    font-weight: 500;
    color: #04b34f;
  }

  &-form-error {
    display: block;
    margin: 0.5rem 0.3rem 0 0.3rem;
    line-height: 1.2;
  }

  &-error {
    position: absolute;
    left: 0.3rem;
    bottom: -1.6rem;
  }
}