@use "../abstracts/mixins" as *;
@use "../abstracts/functions" as *;
@use "../abstracts/variables" as *;

.emoji {

  &-container {
    position: absolute;
    display: none;
    background-color: var(--clr-neutral-white);
    top: 6.5rem;
    right: 0;
    max-width: 30rem;
    border: 1px solid var(--clr-neutral-grey-300);
    border-radius: 1rem;
    z-index: 1;
    flex-wrap: wrap;
    align-items: center;
    padding: 0.5rem 1rem;
    transition: all 0.2s ease-in-out;

    & .emoji {
      cursor: pointer;
      width: 2.75rem;
      height: 2.75rem;
      text-align: center;

      &:hover {
        background-color: var(--clr-neutral-grey-200);
        border-radius: 50%;
      }
    }
  }
}