@use "../abstracts/mixins" as *;
@use "../abstracts/functions" as *;
@use "../abstracts/variables" as *;

.message {

}

.contact {
  @include mq(md) {
    display: flex;
    flex-wrap: wrap;
    gap: 4rem;
  }

  &-message, &-response {
    width: 100%;
    h6 {
      padding: 0 0.5rem;
    }

    @include mq(md) {
      flex: 1;
    }

    header {
      margin-bottom: 1.3rem;
      border-top: 1px solid var(--clr-neutral-grey-200);
      border-bottom: 1px solid var(--clr-neutral-grey-200);

      span {
        display: block;
        font-size: 1.2rem;
        font-weight: 700;
        padding: 0 0.5rem;
      }
    }

    &-body {
      font-size: 1.4rem;
      padding: 0 0.5rem;
    }

  }

  &-response {
    margin-top: 3rem;

    @include mq(md) {
      margin-top: 0;

      .form {
        max-width: 100%;
      }
    }

    .contact-message-body {
      margin-bottom: 1rem;
    }
  }

  &-spam {
    display: inline-block;
    font-size: 1.3rem;
    font-weight: 500;
    background-color: var(--clr-error);
    color: var(--clr-neutral-white);
    padding: 0 0.75rem;
    margin-bottom: 1rem;
  }

  &-new-message {
    font-size: 1.3rem;
    display: inline-block;
    background-color: var(--clr-success-dark);
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    text-align: center;
    color: var(--clr-neutral-white);
  }
}

