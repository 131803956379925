@use "../abstracts/mixins" as *;
@use "../abstracts/functions" as *;
@use "../abstracts/variables" as *;

.container {
  width: 100%;
  max-width: $container-max-width;
  margin: 0 auto;
  padding: 0 1rem;

  @include mq(md) {
    padding: 0 1.5rem;
  }

  @include mq(lg) {
    padding: 0 3rem;
  }

  &:has(.sidebar) {
    position: relative;
    width: 100%;
    display: flex;
    margin: 0;
    padding: 0 0 5rem 0;

    @include mq(lg) {
      padding: 0 3rem 5rem 0;
      max-width: $container-max-width;
    }
  }

  .header & {
    position: relative;
    width: 100%;
    max-width: $container-max-width;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 999;
    padding: 1rem;
    margin: 0;

    @include mq(lg) {
      padding: 1rem 3rem;
    }
  }

  & main {
    margin-top: 6rem;
    flex: 1;

    @include mq(md) {
      max-width: calc($container-max-width - $sidebar-md-width);
      margin-left: $sidebar-md-width;
    }

    @include mq(lg) {
      max-width: $container-max-width;
      margin-left: $sidebar-width;
    }
  }

  .footer & {
    max-width: $container-max-width;
    margin: 0;
  }

  & + .footer & {
    padding-left: 0;

    @include mq(md) {
      width: auto;
      max-width: calc($container-max-width - $sidebar-md-width);
      margin-left: $sidebar-md-width;
    }

    @include mq(lg) {
      max-width: calc($container-max-width - $sidebar-width);
      margin-left: $sidebar-width;
    }
  }
}