@use "../abstracts/mixins" as *;
@use "../abstracts/functions" as *;
@use "../abstracts/variables" as *;

.modal {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: flex-start;
  z-index: 999;
  overflow: auto;
  background-color: alpha($modal-bg-clr, 0.8);
  padding: 0 1.5rem;

  &.modal-show {
    animation: fadeIn 0.2s ease-in-out forwards;
  }

  &.modal-hide {
    animation: fadeOut .2s ease-in-out forwards;
  }

  &-container {
    width: 100%;
    height: auto;
    position: relative;
    background-color: $modal-container-clr;
    margin: 6rem 0 0 0;
    padding: 1rem 1.5rem;
    border-radius: 0.5rem;
    overflow: auto;
    box-shadow: 0.175rem 0.175rem 0.26rem alpha($modal-container-box-shadow-clr, .15);

    @include mq(md) {
      margin: 6rem auto;
      max-width: 50rem;
    }

    form {
      margin-top: 1.5rem;
    }
  }

  header {
    padding: 0 1rem 0 1rem;
    border-bottom: 1px solid $modal-header-border-clr;
  }

  &-title {
    margin: 0 0 0.5rem 0;
  }

  &-content {
    padding: 0.5rem 1rem 1rem 1rem;

    & > * {
      font-size: 1.4rem;
    }
  }

  &-likes {
    position: relative;
    display: flex;
    //margin: 0.5rem 2.5rem 0.5rem 0;
    align-items: center;
    margin: 0.5rem 1.5rem 0 0;
    padding: 0 0 0.5rem 0;

    img {
      border-radius: 50%;
    }

    &-thumb {
      background-color: var(--clr-info);
      position: absolute;
      display: flex;
      left: 1.5rem;
      bottom: 0;
      justify-content: center;
      align-items: center;
      width: 2rem;
      height: 2rem;
      border-radius: 50%;

      i {
        font-size: 1.2rem;
        //bottom: 0.5rem;
        //left: 1.5rem;
      }
    }

    &-user {
      width: 100%;
      padding-left: 1rem;
      align-items: center;
      line-height: 1.5rem;
      border-bottom: 1px solid $modal-footer-border-clr;

      & span {
        display: block;

        &:first-child {
          font-size: 1.2rem;
          font-weight: 500;
        }

        &:nth-child(2) {
          font-size: 1rem;
          font-weight: 500;
        }
      }

      a {
        position: absolute;
        top: 0;
        right: 1rem;
        padding: 0.4rem 1.2rem;
        border-radius: 0.5rem;
        color: white;
        background-color: var(--clr-info);
        transition: all 0.3s ease;

        i {
          font-size: 1.8rem;
        }

        &:hover {
          background-color: var(--clr-info-dark);
        }
      }
    }
  }

  & footer {
    padding: 0 1rem 1rem 1rem;
    border-top: 1px solid $modal-footer-border-clr;

    & > * {
      font-size: 1.6rem;
    }
  }

  &-close {
    position: absolute;
    top: .5rem;
    right: .5rem;
    font-size: 2rem;
    padding: 0 .75rem;
    cursor: pointer;
    user-select: none;
    color: alpha($modal-close-clr, 0.3);
    transition: all .2s ease;

    &:hover,
    &:focus {
      color: alpha($modal-close-hover-clr, 0.6);
    }
  }

  &.modal-show &-container {
    animation: fadeInDown 0.3s ease-in-out forwards;
  }

  &.modal-hide &-container {
    animation: fadeOutUp 0.3s ease-in-out forwards;
  }
}

#cookies-modal .modal-container {
  width: 100%;
  max-width: 80rem;
  max-height: 80%;
  padding-bottom: 1rem;

  @include scrollbar-large;
}