@use "../abstracts/mixins" as *;
@use "../abstracts/functions" as *;
@use "../abstracts/variables" as *;

.menus {
  width: 100%;
  position: fixed;
  bottom: 0;
  height: auto;

  @include mq(md) {
    top: 0.5rem;
    bottom: auto;
  }

  @include mq(lg) {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: 30rem;
    bottom: auto;
    max-width: 30rem;
    margin: 7rem 0 0 0;
    height: auto;
  }
}

.menu {
  position: fixed;
  width: auto;
  max-width: 100%;
  left: 1rem;
  right: 1rem;
  margin: 1rem 1rem 0 1rem;
  padding: 1rem;
  border-radius: 0.5rem;
  border: 1px solid var(--clr-neutral-grey-200);
  background-color: var(--clr-neutral-white);
  bottom: -60rem;
  transition: all .3s ease-in-out;

  @include mq(md) {
    max-width: 40rem;
    top: 6rem;
    bottom: auto;
    left: -60rem;
  }

  &.active {
    position: relative;
    left: 0;
    right: 0;
    bottom: 6rem;

    @include mq(md) {
      top: 6rem;
      bottom: auto;
      left: 5rem;
    }
  }

  &-title {
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    a {
      color: var(--clr-info);
      text-decoration: none;
      font-size: 1.2rem;
      margin-right: 1rem;

      &:hover {
        color: var(--clr-info-dark);
      }
    }
  }

  h6 {
    padding: 0 1rem;
  }


  @include mq(lg) {
    position: relative;
    top: 0;
    right: 0;
    bottom: auto;
    left: auto;
    display: block;
    margin: 1rem 0;
    padding: 0.5rem 0 0 0;
  }

  &-container {
    max-height: 20rem;
    @include scrollbar-small;
  }

  header {
    padding: 0.5rem 1rem;

    & > span {
      font-size: 1rem;
      font-weight: 500;
    }
  }

  &-user {
    display: flex;
    align-items: center;
    font-size: 1.4rem;

    img {
      border-radius: 50%;
      margin-right: 0.5rem;
    }
  }

  &-navbar {
    display: flex;
    justify-content: flex-start;
    padding: 0.5rem 1rem 1rem 1rem;

    @include mq(md) {
      flex-wrap: wrap;
    }

    @include mq(lg) {
      justify-content: space-between;
    }
    ul {
      list-style-type: none;
      margin-right: 2rem;

      li {
          line-height: 1.3;
        a {
          color: var(--clr-info);
          text-decoration: none;
          font-size: 1.4rem;

          &:hover {
            color: var(--clr-info-dark);
          }
        }
      }
    }
  }

  &-friend {
    padding: 0.5rem 0 0.5rem 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid var(--clr-neutral-grey-200);

    img {
      border-radius: 50%;
    }
  }

  &-info {
    margin-left: 0.75rem;

    span {
      display: block;
      font-size: 1rem;
      font-weight: 500;
      line-height: 1.2;

      &.status {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 5.2rem;

        i {
          margin-left: 0.5rem;
          font-size: 1rem;
          color: var(--clr-success);

          &.offline {
            color: var(--clr-neutral-grey-300);
          }
        }
      }
    }
  }

  &-content {
    display: flex;
    align-items: center;

    & p {
      font-size: 1.2rem;
    }
  }

  &-action {
    display: flex;
    padding: 0 1rem;

    a, button {
      margin-left: 0.75rem;

      i {
        font-size: 1.8rem;
      }
    }

    a i {
      color: var(--clr-info);
    }

    button i {
      color: var(--clr-success);
    }
  }
}