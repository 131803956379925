@use "../abstracts/mixins" as *;
@use "../abstracts/functions" as *;
@use "../abstracts/variables" as *;

.cookies {
  & article {
    & ul {
      list-style: none;
      padding-top: 1rem;

      & li {
        padding: .2rem 0;
        transition: all .2s ease;
      }
    }
  }

  & > * a:hover {
    text-decoration: none;
  }
}

#cookies {
  padding: 0.5rem 0;
  width: 100%;
  position: fixed;
  bottom: 0;
  color: $cookies-clr;
  background-color: alpha($cookies-bg-clr, .8);
  z-index: 998;
  transform: translateY(100%);
  transition: transform .5s; /* 2s for demo only */

  &.show {
    display: block;
    transform: translateY(0);
  }

  &.hide {
    display: none;
    transform: translateY(100%);
  }

  & .cookie-consent {
    min-height: 4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;

    & p {
      font-size: 1.4rem;
    }

    & a {
      text-decoration: underline;
      color: $cookies-link-clr;
      font-size: 1.4rem;
      transition: all .3s ease;

      &:hover {
        text-decoration: none;
        color: $cookies-link-hover-clr;
      }
    }
  }

  & .cookie-btn {
    padding: 0 1rem;
    border-radius: 0.5rem;
    color: $cookies-btn-clr;
    background-color: $cookies-btn-bg-clr;
    transition: all 0.2s ease;

    &:hover {
        background-color: $cookies-btn-bg-hover-clr;
    }
  }
}

.cookie-read-btn {
  background-color: transparent;
  transition: color 0.3s ease;
  color: var(--clr-neutral-grey-200);

  &:hover {
    color: var(--clr-neutral-white);
    text-decoration: underline;
  }
}

.auth .cookie-read-btn {
  color: var(--clr-neutral-white);
}

.footer .cookie-read-btn {
  color: inherit;
  font-weight: 500;
}