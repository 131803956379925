@use "../abstracts/mixins" as *;
@use "../abstracts/functions" as *;
@use "../abstracts/variables" as *;

.photo {

  &-album {
    margin-bottom: 1.2rem;
    //display: flex;
    //justify-content: space-between;
    //align-items: center;
    //margin-bottom: 1rem

    &-info {
      border-bottom: 1px solid var(--clr-neutral-grey-200);
      margin-bottom: 1rem;
      gap: 1.2rem;

      @include mq(md) {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
      }

      span {
        text-align: right;
        min-width: 18rem;
        font-size: 1.2rem;
        font-weight: 500;
      }
    }
  }

  &-form {
    min-width: auto;
  }

  &-form-group {
    position: relative;
    margin-bottom: 1.5rem;
    display: flex;

    label, button {
      border-radius: 0.5rem;
      cursor: pointer;
      transition: background-color 0.3s ease;
      padding: 0.2rem 1rem;
      color: var(--clr-neutral-white);
    }

    label {
      margin-right: 0.5rem;
      background-color: var(--clr-info);

      &:hover {
        background-color: var(--clr-info-dark);
      }
    }

    button {
      background-color: var(--clr-success);
      color: var(--clr-neutral-white);

      &:hover {
        background-color: var(--clr-success-dark);
      }
    }
  }
}