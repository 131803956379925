@use "../abstracts/variables" as *;
@use "../abstracts/mixins" as *;

.form {
  width: 100%;

  :is(h1, h2, h3, h4, h5, h6) {
    margin-bottom: 0.7rem;
  }

  @include mq(md) {
    max-width: 52rem;
  }

  background-color: var(--clr-neutral-white);

  &-row {
    @include mq(sm) {
      display: flex;
      justify-content: space-between;
      gap: 2rem;
    }
  }

  &-auth {
    width: calc(100vw - 3rem);
    padding: 0.5rem 2.5rem 2.5rem 2.5rem;
    border-radius: 0.5rem;
    border: 1px solid var(--clr-neutral-grey-200);
    margin: 0 1.5rem;

    @include mq(md) {
      margin: 0;
      width: $form-auth-width;
    }
  }

  :is(h1, h2, h3, h4, h5, h6) {
    margin-bottom: 0.7rem;
  }

  input:not(input[type="checkbox"], input[type="radio"]),
  select, textarea, & button, &-btn, &-btn-cancel {
    font-size: $form-input-size;
    border-radius: 0.5rem;
    line-height: 1.3;
  }

  input:not(input[type="checkbox"], input[type="radio"], input[type="file"]),
  select, textarea {
    padding: 0.75rem 1rem;
  }

  &-group > i {
    position: absolute;
    right: 1rem;
    top: 70%;
    color: var(--clr-neutral-grey-300);
    cursor: pointer;
    transform: translateY(-50%);
  }

  &-group > i.active::before {
    content: "\f06e";
    color: var(--clr-neutral-grey-700);
  }

  & button, &-btn, &-btn-cancel {
    text-decoration: none;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0.75rem 1.5rem;
    border-radius: 0.5rem;
    transition: all .3s ease;
    -webkit-appearance: none;
  }

  &-search {
    position: relative;
    display: flex;
    align-items: center;

    #search {
      border-radius: 0.5rem 0 0 0.5rem;
    }
  }

  textarea {
    height: 100%;
    min-height: 10rem;
    resize: vertical;
  }

  &-group {
    position: relative;

    &-inline {
      position: relative;
      display: flex;
      margin-bottom: 0.6rem;

      .form-label {
        font-size: 1.2rem;
        font-weight: 500;
        margin: 0 0 0 0.5rem;
      }
    }
  }


  @include mq(sm) {
    &-row &-group {
      flex: 1;
    }
  }

  &-label {
    position: relative;
    display: block;
    margin: 0.5rem 0 0 0.3rem;
    font-size: 1.5rem;

    &-sr-only {
      border: 0;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }
  }

  &-btn {
    i {
      padding-right: 0.5rem;
    }
  }

  & button, &-btn {
    background-color: $form-btn-bg-clr;
    color: $form-btn-clr;
    border: 1px solid $form-btn-bg-clr;

    &:hover {
      background-color: $form-btn-bg-hover-clr;
      border: 1px solid $form-btn-bg-hover-clr;
    }
  }

  &-btn-cancel {
    background-color: $form-cancel-btn-bg-clr;
    color: $form-cancel-btn-clr;
    border: 1px solid $form-cancel-btn-clr;

    &:hover {
      color: $form-cancel-btn-hover-clr;
      border: 1px solid $form-cancel-btn-hover-clr;
    }
  }

  &-search button {
    border-radius: 0 .5rem .5rem 0;
  }

  &-upload-btn {
    position: relative;

    i {
      position: absolute;
      left: 0;
      font-size: 2rem;
      padding: 0.9rem 1.5rem;
      height: 100%;
      border-top-left-radius: .5rem;
      border-bottom-left-radius: .5rem;
      background-color: $form-btn-bg-clr;
      color: $form-btn-clr;
    }

    & > input[type="file"] {
      width: 100%;
      position: relative;
      font-size: 1.4rem;
      color: var(--clr-neutral-grey-400);
      background-color: transparent;
      cursor: pointer;
      padding: 0.432rem 0;
    }
  }

  &-actions {
    position: relative;
    margin-top: 2rem;

    >:not(:first-child) {
      margin-left: .5rem;
    }

    a {
      font-size: 1.5rem;
    }
  }
}