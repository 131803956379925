@use "../abstracts/variables" as *;
@use "../abstracts/functions" as *;
@use "../abstracts/mixins" as *;

body {
  font-family: system-ui, sans-serif;
  min-height: 100vh;
  font-size: 1.6rem;
  line-height: 1.6;
  letter-spacing: 0.1rem;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

main.center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 6rem - 4rem - 4rem); // 100vh - header - flash - footer

}

main:not(.auth-main, .error-main) {
  position: relative;
  margin-top: 6rem;
  width: 100%;

  @include mq(md) {
    margin-left: 5rem;
  }

  @include mq(lg) {
    margin-left: $sidebar-width;
  }
}

article {
  margin-bottom: 2rem;
}

body > footer {
  position: sticky;
  top: 100vh;
}

.valid {
  color: var(--clr-success);
}

.spam {
  color: var(--clr-error);
}

.spam-box {
  display: inline-block;
  background-color: var(--clr-error);
  color: var(--clr-neutral-white);
  padding: 0.2rem 0.75rem;
  font-size: 1.2rem;
  font-weight: 500;
}

.px-small {
  padding-left: 1rem;
  padding-right: 1rem;

  @include mq(md) {
    padding-left: 0;
    padding-right: 0;
  }
}

body#dashboard {
  h6 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .table {
    margin-bottom: 1rem;
  }
}