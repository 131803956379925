@use "../abstracts/mixins" as *;
@use "../abstracts/functions" as *;
@use "../abstracts/variables" as *;

.btn, .btn-small, .btn-large {
  font-size: $form-input-size;
  border-radius: 0.5rem;
  line-height: 1.3;
  text-decoration: none !important;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.75rem 1.5rem;
  transition: all .3s ease;
  -webkit-appearance: none;
  background-color: $form-btn-bg-clr;
  color: $form-btn-clr;
  border: 1px solid $form-btn-bg-clr;

  &:hover {
    background-color: $form-btn-bg-hover-clr;
    border: 1px solid $form-btn-bg-hover-clr;
  }

  i {
    margin-right: 0.5rem;
  }
}

.btn-block {
  width: 100%;
}

.btn-large {
  padding: 0.9rem 2rem;
  font-size: 1.7rem;
  line-height: 1.4;
}

.btn-small {
  text-decoration: none;
  padding: 0.5rem 1rem;
  font-size: 1.3rem;
}