@use "../abstracts/mixins" as *;
@use "../abstracts/functions" as *;
@use "../abstracts/variables" as *;

.albums {
  margin-top: 2rem;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 1rem;
  justify-content: center;

  @include mq(sm) {
    justify-content: flex-start;
  }

  a {
    text-decoration: none;
  }
}

.album {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 0.5rem;
  width: 15rem;
  height: 15rem;
  background-color: var(--clr-info);

  & > a {
    width: 100%;
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: var(--clr-neutral-white);
    transition: background-color 0.3s ease;

    &:hover {
      border-bottom-left-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem;
      background-color: var(--clr-info-dark);
    }

    &::before {
      text-align: center;
      vertical-align: center;
      font-family: "Font Awesome 6 Free", serif;
      content: "\f302";
      color: alpha($clr-white, 0.3);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      font-size: 8rem;

      opacity: 0.5;
    }
  }

  &-name {
    padding: 1rem 1rem 0 1rem;
    font-size: 1.4rem;
    text-align: center;
    line-height: 1.3;
  }

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.2rem 1rem;
    width: 100%;
    background-color: var(--clr-info);
    color: var(--clr-neutral-white);
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    transition: background-color 0.3s ease;

    span {
      font-size: 1.2rem;
      font-weight: 500;
      color: var(--clr-neutral-white);
    }

    &:hover {
      border-top-left-radius: 0.5rem;
      border-top-right-radius: 0.5rem;
      background-color: var(--clr-info-dark);
    }
  }

  &-actions {
    display: flex;

    i {
      font-size: 1.8rem;
      margin-left: 0.5rem;
    }

    form {
      display: inline-block;
    }

    button {
      background-color: transparent;
      margin-left: 0.2rem;
    }
  }

  &-visible {
    color: var(--clr-neutral-white);
  }

  &-hidden {
    color: var(--clr-neutral-grey-200);
  }

  footer {
    padding: 0.2rem 1rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 1rem;
    font-weight: 500;
  }
}