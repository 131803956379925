*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
  scroll-padding: 5.8rem;
  //color-scheme: dark light;
}

html:focus-within {
  scroll-behavior: smooth;
}

body {
  text-rendering: optimizeSpeed;
  box-sizing: border-box;
}

h1,
h2,
h3 {
  text-indent: -0.07em;
}

.display-1-xl {
    text-indent: -0.05em;
}

a:not([class]) {
  text-decoration-skip-ink: auto;
}

img {
  font-style: italic;
  background-repeat: no-repeat;
  background-size: cover;
  shape-margin: 1rem;
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

picture,
svg,
video {
  display: block;
  max-width: 100%;
}

ul[role="list"],
ol[role="list"] {
  list-style: none;
}

a {
  transition: text-decoration 0.3s ease;
}

a,
a:visited,
a:active {
  text-decoration: underline;
}

a:hover {
  text-decoration: none;
}

[class^='text-'],
[class^='bg-'] {
  transition: 0.3s ease;
}

button {
  cursor: pointer;
  border: 0;
  border-radius: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  background-color: var(--clr-neutral-white);
  letter-spacing: 0.1rem;
}

button, select {
  text-transform: none;
}

input:focus,
textarea:focus,
select:focus {
  outline: none !important;
  border: 1px solid var(--clr-neutral-grey-300) !important;
}

::-webkit-file-upload-button,
::file-selector-button {
  font: inherit;
  visibility: hidden;
  vertical-align: middle;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus textarea:-webkit-autofill,
input:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  box-shadow: 0 0 0 1000px var(--clr-neutral-white) inset !important;
  -webkit-box-shadow: 0 0 0 1000px var(--clr-neutral-white) inset !important;
}

input:-webkit-autofill::first-line,
select:-webkit-autofill::first-line,
textarea:-webkit-autofill::first-line {
  font-family: inherit !important;
  font-size: 1.4rem !important;
}



::placeholder {
  font-family: inherit !important;
  font-size: 1.4rem !important;
  color: var(--clr-neutral-grey-400);
}

textarea {
  margin-top: 1px;
}

input:not(input[type="checkbox"], input[type="radio"]), textarea, select {
    border: 1px solid var(--clr-neutral-grey-200);
}

input:not(input[type="checkbox"], input[type="radio"], input[type="file"]),
select, textarea {
  position: relative;
  display: flex;
  width: 100%;
}

// Remove all animations, transitions and smooth scroll for people that prefer not to see them
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
