@use "../abstracts/mixins" as *;
@use "../abstracts/functions" as *;
@use "../abstracts/variables" as *;

.flash {
  width: 100%;

  & [class*="box"] {
    font-size: $font-size-flash;
    font-weight: 700;
    padding: 1rem 0;
    border-radius: .5rem;
    letter-spacing: 0.1rem;
  }

  & p {
    font-size: $font-size-flash;
    font-weight: 500;
    padding: 1rem 0;
    letter-spacing: 0.1rem;
  }

  &-success {
    color: var(--clr-success-dark);

    &-box {
      color: var(--clr-success-dark);
      border: 1px solid var(--clr-success-dark);
      background-color: lightness(var(--clr-success), 1.8);
    }
  }

  &-info {
    color: var(--clr-info-dark);

    &-box {
      color: var(--clr-info-dark);
      border: 1px solid var(--clr-info-dark);
      background-color: lightness(var(--clr-info), 1.8);
    }
  }

  &-warning {
    color: var(--clr-warning-dark);

    &-box {
      color: var(--clr-warning-dark);
      border: 1px solid var(--clr-warning-dark);
      background-color: lightness(var(--clr-warning), 1.8);
    }
  }

  &-error {
    color: var(--clr-error-dark);

    &-box {
      color: var(--clr-error-dark);
      border: 1px solid var(--clr-error-dark);
      background-color: lightness(var(--clr-error), 2);
    }
  }

  &-enlightenment {
    color: var(--clr-neutral-grey-700);

    &-box {
      color: var(--clr-neutral-grey-800);
      border: 1px solid var(--clr-neutral-grey-300);
      background-color: lightness(var(--clr-neutral-grey-200), 1.8);
    }
  }

  &-enlightenment-light {
    color: var(--clr-neutral-white);

    &-box {
      color: var(--clr-neutral-grey-800);
      border: 1px solid var(--clr-neutral-grey-300);
      background-color: var(--clr-neutral-white)
    }
  }


}

.auth {
  position: relative;

  .flash {
    position: absolute;
    top: 0;
  }
}