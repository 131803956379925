:root {
  --clr-primary-darker-h: 204;
  --clr-primary-darker-s: 63.7%;
  --clr-primary-darker-l: 24.3%;
  --clr-primary-darker: hsl(var(--clr-primary-darker-h), var(--clr-primary-darker-s), var(--clr-primary-darker-l));
  --clr-primary-dark-h: 204;
  --clr-primary-dark-s: 63.7%;
  --clr-primary-dark-l: 34.3%;
  --clr-primary-dark: hsl(var(--clr-primary-dark-h), var(--clr-primary-dark-s), var(--clr-primary-dark-l));
  --clr-primary-h: 204;
  --clr-primary-s: 63.7%;
  --clr-primary-l: 44.3%;
  --clr-primary: hsl(var(--clr-primary-h), var(--clr-primary-s), var(--clr-primary-l));
  --clr-primary-light-h: 204;
  --clr-primary-light-s: 63.7%;
  --clr-primary-light-l: 54.3%;
  --clr-primary-light: hsl(var(--clr-primary-light-h), var(--clr-primary-light-s), var(--clr-primary-light-l));
  --clr-primary-lighter-h: 204;
  --clr-primary-lighter-s: 63.7%;
  --clr-primary-lighter-l: 64.3%;
  --clr-primary-lighter: hsl(var(--clr-primary-lighter-h), var(--clr-primary-lighter-s), var(--clr-primary-lighter-l));
  --clr-secondary-darker-h: 168;
  --clr-secondary-darker-s: 76%;
  --clr-secondary-darker-l: 16%;
  --clr-secondary-darker: hsl(var(--clr-secondary-darker-h), var(--clr-secondary-darker-s), var(--clr-secondary-darker-l));
  --clr-secondary-dark-h: 168;
  --clr-secondary-dark-s: 76%;
  --clr-secondary-dark-l: 26%;
  --clr-secondary-dark: hsl(var(--clr-secondary-dark-h), var(--clr-secondary-dark-s), var(--clr-secondary-dark-l));
  --clr-secondary-h: 168;
  --clr-secondary-s: 76%;
  --clr-secondary-l: 36%;
  --clr-secondary: hsl(var(--clr-secondary-h), var(--clr-secondary-s), var(--clr-secondary-l));
  --clr-secondary-light-h: 168;
  --clr-secondary-light-s: 76%;
  --clr-secondary-light-l: 46%;
  --clr-secondary-light: hsl(var(--clr-secondary-light-h), var(--clr-secondary-light-s), var(--clr-secondary-light-l));
  --clr-secondary-lighter-h: 168;
  --clr-secondary-lighter-s: 76%;
  --clr-secondary-lighter-l: 56%;
  --clr-secondary-lighter: hsl(var(--clr-secondary-lighter-h), var(--clr-secondary-lighter-s), var(--clr-secondary-lighter-l));
  --clr-error-darker-h: 6;
  --clr-error-darker-s: 63%;
  --clr-error-darker-l: 26%;
  --clr-error-darker: hsl(var(--clr-error-darker-h), var(--clr-error-darker-s), var(--clr-error-darker-l));
  --clr-error-dark-h: 6;
  --clr-error-dark-s: 63%;
  --clr-error-dark-l: 36%;
  --clr-error-dark: hsl(var(--clr-error-dark-h), var(--clr-error-dark-s), var(--clr-error-dark-l));
  --clr-error-h: 6;
  --clr-error-s: 63%;
  --clr-error-l: 46%;
  --clr-error: hsl(var(--clr-error-h), var(--clr-error-s), var(--clr-error-l));
  --clr-error-light-h: 6;
  --clr-error-light-s: 63%;
  --clr-error-light-l: 56%;
  --clr-error-light: hsl(var(--clr-error-light-h), var(--clr-error-light-s), var(--clr-error-light-l));
  --clr-error-lighter-h: 6;
  --clr-error-lighter-s: 63%;
  --clr-error-lighter-l: 66%;
  --clr-error-lighter: hsl(var(--clr-error-lighter-h), var(--clr-error-lighter-s), var(--clr-error-lighter-l));
  --clr-warning-darker-h: 37;
  --clr-warning-darker-s: 90%;
  --clr-warning-darker-l: 31%;
  --clr-warning-darker: hsl(var(--clr-warning-darker-h), var(--clr-warning-darker-s), var(--clr-warning-darker-l));
  --clr-warning-dark-h: 37;
  --clr-warning-dark-s: 90%;
  --clr-warning-dark-l: 41%;
  --clr-warning-dark: hsl(var(--clr-warning-dark-h), var(--clr-warning-dark-s), var(--clr-warning-dark-l));
  --clr-warning-h: 37;
  --clr-warning-s: 90%;
  --clr-warning-l: 51%;
  --clr-warning: hsl(var(--clr-warning-h), var(--clr-warning-s), var(--clr-warning-l));
  --clr-warning-light-h: 37;
  --clr-warning-light-s: 90%;
  --clr-warning-light-l: 61%;
  --clr-warning-light: hsl(var(--clr-warning-light-h), var(--clr-warning-light-s), var(--clr-warning-light-l));
  --clr-warning-lighter-h: 37;
  --clr-warning-lighter-s: 90%;
  --clr-warning-lighter-l: 71%;
  --clr-warning-lighter: hsl(var(--clr-warning-lighter-h), var(--clr-warning-lighter-s), var(--clr-warning-lighter-l));
  --clr-info-darker-h: 204;
  --clr-info-darker-s: 69.9%;
  --clr-info-darker-l: 33.1%;
  --clr-info-darker: hsl(var(--clr-info-darker-h), var(--clr-info-darker-s), var(--clr-info-darker-l));
  --clr-info-dark-h: 204;
  --clr-info-dark-s: 69.9%;
  --clr-info-dark-l: 43.1%;
  --clr-info-dark: hsl(var(--clr-info-dark-h), var(--clr-info-dark-s), var(--clr-info-dark-l));
  --clr-info-h: 204;
  --clr-info-s: 69.9%;
  --clr-info-l: 53.1%;
  --clr-info: hsl(var(--clr-info-h), var(--clr-info-s), var(--clr-info-l));
  --clr-info-light-h: 204;
  --clr-info-light-s: 69.9%;
  --clr-info-light-l: 63.1%;
  --clr-info-light: hsl(var(--clr-info-light-h), var(--clr-info-light-s), var(--clr-info-light-l));
  --clr-info-lighter-h: 204;
  --clr-info-lighter-s: 69.9%;
  --clr-info-lighter-l: 73.1%;
  --clr-info-lighter: hsl(var(--clr-info-lighter-h), var(--clr-info-lighter-s), var(--clr-info-lighter-l));
  --clr-success-darker-h: 145;
  --clr-success-darker-s: 63.2%;
  --clr-success-darker-l: 29%;
  --clr-success-darker: hsl(var(--clr-success-darker-h), var(--clr-success-darker-s), var(--clr-success-darker-l));
  --clr-success-dark-h: 145;
  --clr-success-dark-s: 63.2%;
  --clr-success-dark-l: 39%;
  --clr-success-dark: hsl(var(--clr-success-dark-h), var(--clr-success-dark-s), var(--clr-success-dark-l));
  --clr-success-h: 145;
  --clr-success-s: 63.2%;
  --clr-success-l: 49%;
  --clr-success: hsl(var(--clr-success-h), var(--clr-success-s), var(--clr-success-l));
  --clr-success-light-h: 145;
  --clr-success-light-s: 63.2%;
  --clr-success-light-l: 59%;
  --clr-success-light: hsl(var(--clr-success-light-h), var(--clr-success-light-s), var(--clr-success-light-l));
  --clr-success-lighter-h: 145;
  --clr-success-lighter-s: 63.2%;
  --clr-success-lighter-l: 69%;
  --clr-success-lighter: hsl(var(--clr-success-lighter-h), var(--clr-success-lighter-s), var(--clr-success-lighter-l));
  --clr-neutral-black-h: 0;
  --clr-neutral-black-s: 0%;
  --clr-neutral-black-l: 0%;
  --clr-neutral-black: hsl(var(--clr-neutral-black-h), var(--clr-neutral-black-s), var(--clr-neutral-black-l));
  --clr-neutral-grey-900-h: 0;
  --clr-neutral-grey-900-s: 0%;
  --clr-neutral-grey-900-l: 18%;
  --clr-neutral-grey-900: hsl(var(--clr-neutral-grey-900-h), var(--clr-neutral-grey-900-s), var(--clr-neutral-grey-900-l));
  --clr-neutral-grey-800-h: 0;
  --clr-neutral-grey-800-s: 0%;
  --clr-neutral-grey-800-l: 28%;
  --clr-neutral-grey-800: hsl(var(--clr-neutral-grey-800-h), var(--clr-neutral-grey-800-s), var(--clr-neutral-grey-800-l));
  --clr-neutral-grey-700-h: 0;
  --clr-neutral-grey-700-s: 0%;
  --clr-neutral-grey-700-l: 38%;
  --clr-neutral-grey-700: hsl(var(--clr-neutral-grey-700-h), var(--clr-neutral-grey-700-s), var(--clr-neutral-grey-700-l));
  --clr-neutral-grey-600-h: 0;
  --clr-neutral-grey-600-s: 0%;
  --clr-neutral-grey-600-l: 48%;
  --clr-neutral-grey-600: hsl(var(--clr-neutral-grey-600-h), var(--clr-neutral-grey-600-s), var(--clr-neutral-grey-600-l));
  --clr-neutral-grey-500-h: 0;
  --clr-neutral-grey-500-s: 0%;
  --clr-neutral-grey-500-l: 58%;
  --clr-neutral-grey-500: hsl(var(--clr-neutral-grey-500-h), var(--clr-neutral-grey-500-s), var(--clr-neutral-grey-500-l));
  --clr-neutral-grey-400-h: 0;
  --clr-neutral-grey-400-s: 0%;
  --clr-neutral-grey-400-l: 68%;
  --clr-neutral-grey-400: hsl(var(--clr-neutral-grey-400-h), var(--clr-neutral-grey-400-s), var(--clr-neutral-grey-400-l));
  --clr-neutral-grey-300-h: 0;
  --clr-neutral-grey-300-s: 0%;
  --clr-neutral-grey-300-l: 78%;
  --clr-neutral-grey-300: hsl(var(--clr-neutral-grey-300-h), var(--clr-neutral-grey-300-s), var(--clr-neutral-grey-300-l));
  --clr-neutral-grey-200-h: 0;
  --clr-neutral-grey-200-s: 0%;
  --clr-neutral-grey-200-l: 88%;
  --clr-neutral-grey-200: hsl(var(--clr-neutral-grey-200-h), var(--clr-neutral-grey-200-s), var(--clr-neutral-grey-200-l));
  --clr-neutral-grey-100-h: 0;
  --clr-neutral-grey-100-s: 0%;
  --clr-neutral-grey-100-l: 98%;
  --clr-neutral-grey-100: hsl(var(--clr-neutral-grey-100-h), var(--clr-neutral-grey-100-s), var(--clr-neutral-grey-100-l));
  --clr-neutral-white-h: 0;
  --clr-neutral-white-s: 0%;
  --clr-neutral-white-l: 100%;
  --clr-neutral-white: hsl(var(--clr-neutral-white-h), var(--clr-neutral-white-s), var(--clr-neutral-white-l));
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
  scroll-padding: 5.8rem;
}

html:focus-within {
  scroll-behavior: smooth;
}

body {
  text-rendering: optimizeSpeed;
  box-sizing: border-box;
}

h1,
h2,
h3 {
  text-indent: -0.07em;
}

.display-1-xl {
  text-indent: -0.05em;
}

a:not([class]) {
  text-decoration-skip-ink: auto;
}

img {
  font-style: italic;
  background-repeat: no-repeat;
  background-size: cover;
  shape-margin: 1rem;
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

picture,
svg,
video {
  display: block;
  max-width: 100%;
}

ul[role=list],
ol[role=list] {
  list-style: none;
}

a {
  transition: text-decoration 0.3s ease;
}

a,
a:visited,
a:active {
  text-decoration: underline;
}

a:hover {
  text-decoration: none;
}

[class^=text-],
[class^=bg-] {
  transition: 0.3s ease;
}

button {
  cursor: pointer;
  border: 0;
  border-radius: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  background-color: var(--clr-neutral-white);
  letter-spacing: 0.1rem;
}

button, select {
  text-transform: none;
}

input:focus,
textarea:focus,
select:focus {
  outline: none !important;
  border: 1px solid var(--clr-neutral-grey-300) !important;
}

::-webkit-file-upload-button,
::file-selector-button {
  font: inherit;
  visibility: hidden;
  vertical-align: middle;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus textarea:-webkit-autofill,
input:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  box-shadow: 0 0 0 1000px var(--clr-neutral-white) inset !important;
  -webkit-box-shadow: 0 0 0 1000px var(--clr-neutral-white) inset !important;
}

input:-webkit-autofill::first-line,
select:-webkit-autofill::first-line,
textarea:-webkit-autofill::first-line {
  font-family: inherit !important;
  font-size: 1.4rem !important;
}

::placeholder {
  font-family: inherit !important;
  font-size: 1.4rem !important;
  color: var(--clr-neutral-grey-400);
}

textarea {
  margin-top: 1px;
}

input:not(input[type=checkbox], input[type=radio]), textarea, select {
  border: 1px solid var(--clr-neutral-grey-200);
}

input:not(input[type=checkbox], input[type=radio], input[type=file]),
select, textarea {
  position: relative;
  display: flex;
  width: 100%;
}

@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
.text-primary {
  color: var(--clr-primary) !important;
}

.text-primary-hover:hover, .text-primary.active {
  color: var(--clr-primary-dark) !important;
}

.bg-primary {
  background-color: var(--clr-primary) !important;
}

.bg-primary-hover:hover, .bg-primary.active {
  background-color: var(--clr-primary-dark) !important;
}

.border-primary {
  border-color: var(--clr-primary) !important;
}

.btn-primary {
  color: var(--clr-neutral-white) !important;
  background-color: var(--clr-primary) !important;
  border: 1px solid var(--clr-primary) !important;
}
.btn-primary:hover {
  background-color: var(--clr-primary-dark) !important;
  border: 1px solid var(--clr-primary-dark) !important;
}

.text-secondary {
  color: var(--clr-secondary) !important;
}

.text-secondary-hover:hover, .text-secondary.active {
  color: var(--clr-secondary-dark) !important;
}

.bg-secondary {
  background-color: var(--clr-secondary) !important;
}

.bg-secondary-hover:hover, .bg-secondary.active {
  background-color: var(--clr-secondary-dark) !important;
}

.border-secondary {
  border-color: var(--clr-secondary) !important;
}

.btn-secondary {
  color: var(--clr-neutral-white) !important;
  background-color: var(--clr-secondary) !important;
  border: 1px solid var(--clr-secondary) !important;
}
.btn-secondary:hover {
  background-color: var(--clr-secondary-dark) !important;
  border: 1px solid var(--clr-secondary-dark) !important;
}

.text-error {
  color: var(--clr-error) !important;
}

.text-error-hover:hover, .text-error.active {
  color: var(--clr-error-dark) !important;
}

.bg-error {
  background-color: var(--clr-error) !important;
}

.bg-error-hover:hover, .bg-error.active {
  background-color: var(--clr-error-dark) !important;
}

.border-error {
  border-color: var(--clr-error) !important;
}

.btn-error {
  color: var(--clr-neutral-white) !important;
  background-color: var(--clr-error) !important;
  border: 1px solid var(--clr-error) !important;
}
.btn-error:hover {
  background-color: var(--clr-error-dark) !important;
  border: 1px solid var(--clr-error-dark) !important;
}

.text-warning {
  color: var(--clr-warning) !important;
}

.text-warning-hover:hover, .text-warning.active {
  color: var(--clr-warning-dark) !important;
}

.bg-warning {
  background-color: var(--clr-warning) !important;
}

.bg-warning-hover:hover, .bg-warning.active {
  background-color: var(--clr-warning-dark) !important;
}

.border-warning {
  border-color: var(--clr-warning) !important;
}

.btn-warning {
  color: var(--clr-neutral-white) !important;
  background-color: var(--clr-warning) !important;
  border: 1px solid var(--clr-warning) !important;
}
.btn-warning:hover {
  background-color: var(--clr-warning-dark) !important;
  border: 1px solid var(--clr-warning-dark) !important;
}

.text-info {
  color: var(--clr-info) !important;
}

.text-info-hover:hover, .text-info.active {
  color: var(--clr-info-dark) !important;
}

.bg-info {
  background-color: var(--clr-info) !important;
}

.bg-info-hover:hover, .bg-info.active {
  background-color: var(--clr-info-dark) !important;
}

.border-info {
  border-color: var(--clr-info) !important;
}

.btn-info {
  color: var(--clr-neutral-white) !important;
  background-color: var(--clr-info) !important;
  border: 1px solid var(--clr-info) !important;
}
.btn-info:hover {
  background-color: var(--clr-info-dark) !important;
  border: 1px solid var(--clr-info-dark) !important;
}

.text-success {
  color: var(--clr-success) !important;
}

.text-success-hover:hover, .text-success.active {
  color: var(--clr-success-dark) !important;
}

.bg-success {
  background-color: var(--clr-success) !important;
}

.bg-success-hover:hover, .bg-success.active {
  background-color: var(--clr-success-dark) !important;
}

.border-success {
  border-color: var(--clr-success) !important;
}

.btn-success {
  color: var(--clr-neutral-white) !important;
  background-color: var(--clr-success) !important;
  border: 1px solid var(--clr-success) !important;
}
.btn-success:hover {
  background-color: var(--clr-success-dark) !important;
  border: 1px solid var(--clr-success-dark) !important;
}

.text-dark {
  color: var(--clr-neutral-grey-700) !important;
}

.text-dark-hover:hover, .text-dark.active {
  color: var(--clr-neutral-grey-800) !important;
}

.bg-dark {
  background-color: var(--clr-neutral-grey-700) !important;
}

.bg-dark-hover:hover, .bg-dark.active {
  background-color: var(--clr-neutral-grey-800) !important;
}

.btn-dark {
  color: var(--clr-neutral-white) !important;
  background-color: var(--clr-neutral-grey-700) !important;
  border: 1px solid var(--clr-neutral-grey-700) !important;
}
.btn-dark:hover {
  background-color: var(--clr-neutral-grey-800) !important;
  border: 1px solid var(--clr-neutral-grey-800) !important;
}

.text-white {
  color: var(--clr-neutral-white) !important;
}

.text-white-hover:hover, .text-white.active {
  color: var(--clr-neutral-grey-200) !important;
}

.text-disabled {
  color: var(--clr-neutral-grey-300) !important;
}

.bg-disabled {
  background-color: var(--clr-neutral-grey-100) !important;
}

.bg-white {
  background-color: var(--clr-neutral-white) !important;
}

.bg-white-hover:hover, .bg-white.active {
  color: var(--clr-neutral-grey-200) !important;
}

.btn-light {
  color: var(--clr-neutral-grey-700) !important;
  background-color: var(--clr-neutral-grey-100) !important;
  border: 1px solid var(--clr-neutral-grey-100) !important;
}
.btn-light:hover {
  background-color: var(--clr-neutral-grey-200) !important;
  border: 1px solid var(--clr-neutral-grey-200) !important;
}

.btn-disabled {
  color: var(--clr-neutral-grey-300) !important;
  border: 1px solid var(--clr-neutral-grey-300) !important;
  background-color: var(--clr-neutral-grey-100) !important;
  cursor: initial;
}

.btn-cancel {
  background-color: var(--clr-neutral-white) !important;
  color: var(--clr-neutral-grey-400) !important;
  border: 1px solid var(--clr-neutral-grey-400) !important;
}
.btn-cancel:hover {
  color: var(--clr-neutral-grey-700) !important;
  border: 1px solid var(--clr-neutral-grey-700) !important;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-6rem);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes fadeOutUp {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-6rem);
  }
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes zoom {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
body {
  font-family: system-ui, sans-serif;
  min-height: 100vh;
  font-size: 1.6rem;
  line-height: 1.6;
  letter-spacing: 0.1rem;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

main.center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 6rem - 4rem - 4rem);
}

main:not(.auth-main, .error-main) {
  position: relative;
  margin-top: 6rem;
  width: 100%;
}
@media only screen and (min-width: 45em) {
  main:not(.auth-main, .error-main) {
    margin-left: 5rem;
  }
}
@media only screen and (min-width: 60em) {
  main:not(.auth-main, .error-main) {
    margin-left: 20rem;
  }
}

article {
  margin-bottom: 2rem;
}

body > footer {
  position: sticky;
  top: 100vh;
}

.valid {
  color: var(--clr-success);
}

.spam {
  color: var(--clr-error);
}

.spam-box {
  display: inline-block;
  background-color: var(--clr-error);
  color: var(--clr-neutral-white);
  padding: 0.2rem 0.75rem;
  font-size: 1.2rem;
  font-weight: 500;
}

.px-small {
  padding-left: 1rem;
  padding-right: 1rem;
}
@media only screen and (min-width: 45em) {
  .px-small {
    padding-left: 0;
    padding-right: 0;
  }
}

body#dashboard h6 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
body#dashboard .table {
  margin-bottom: 1rem;
}

h1, h2, h3, h4, h5, h6 {
  font-family: inherit;
  font-weight: 500;
}

h1 {
  margin-bottom: 1rem;
}
@media only screen and (min-width: 45em) {
  h1 {
    margin-bottom: 2.5rem;
  }
}

h2 {
  margin-bottom: 1rem;
}

h3 {
  margin-bottom: 0.8rem;
}

h4 {
  margin-bottom: 0.6rem;
}

h5 {
  margin-bottom: 0.4rem;
}

h6 {
  margin-bottom: 0.2rem;
}

h1 {
  font-size: clamp(1.975rem, 1.975rem + 1.5vw, 4.5rem);
}

h2 {
  font-size: clamp(1.925rem, 1.925rem + 0.9vw, 3rem);
}

h3 {
  font-size: clamp(1.9rem, 1.9rem + 0.6vw, 2.6rem);
}

h4 {
  font-size: clamp(1.875rem, 1.875rem + 0.3vw, 2.2rem);
}

h5 {
  font-size: clamp(1.825rem, 1.825rem + 0.3vw, 1.9rem);
}

h6 {
  font-size: clamp(1.7rem, 1.7rem + 1.5vw, 1.7rem);
}

.display-1 {
  font-family: inherit;
  font-size: calc(1.975rem + 3.5vw);
  font-weight: 300;
  color: var(--clr-neutral-grey-800);
}

.display-1-xl {
  font-family: inherit;
  font-size: clamp(4rem, 4rem + 5vw, 11rem);
  font-weight: 300;
  color: var(--clr-neutral-grey-800);
}

.display-2 {
  font-family: inherit;
  font-size: calc(1.875rem + 2.5vw);
  font-weight: 300;
  color: var(--clr-neutral-grey-800);
}

.display-3 {
  font-family: inherit;
  font-size: calc(1.8rem + 1.7vw);
  font-weight: 300;
  color: var(--clr-neutral-grey-800);
}

p {
  font-size: 1.6rem;
  margin-top: 0;
  letter-spacing: 0.1rem;
}
p:not(:last-of-type) {
  padding-bottom: 1rem;
}
@media only screen and (min-width: 45em) {
  p:not(:last-of-type) {
    padding-bottom: 1.5rem;
  }
}
p.para-lg {
  font-size: 1.6rem;
}
@media only screen and (min-width: 45em) {
  p.para-lg {
    font-size: 1.8rem;
  }
}

.btn, .btn-small, .btn-large {
  font-size: 1.5rem;
  border-radius: 0.5rem;
  line-height: 1.3;
  text-decoration: none !important;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.75rem 1.5rem;
  transition: all 0.3s ease;
  -webkit-appearance: none;
  background-color: var(--clr-neutral-grey-600);
  color: var(--clr-neutral-white);
  border: 1px solid var(--clr-neutral-grey-600);
}
.btn:hover, .btn-small:hover, .btn-large:hover {
  background-color: var(--clr-neutral-grey-700);
  border: 1px solid var(--clr-neutral-grey-700);
}
.btn i, .btn-small i, .btn-large i {
  margin-right: 0.5rem;
}

.btn-block {
  width: 100%;
}

.btn-large {
  padding: 0.9rem 2rem;
  font-size: 1.7rem;
  line-height: 1.4;
}

.btn-small {
  text-decoration: none;
  padding: 0.5rem 1rem;
  font-size: 1.3rem;
}

.flash {
  width: 100%;
}
.flash [class*=box] {
  font-size: 1.2rem !important;
  font-weight: 700;
  padding: 1rem 0;
  border-radius: 0.5rem;
  letter-spacing: 0.1rem;
}
.flash p {
  font-size: 1.2rem !important;
  font-weight: 500;
  padding: 1rem 0;
  letter-spacing: 0.1rem;
}
.flash-success {
  color: var(--clr-success-dark);
}
.flash-success-box {
  color: var(--clr-success-dark);
  border: 1px solid var(--clr-success-dark);
  background-color: hsl(var(--clr-success-h), var(--clr-success-s), calc(var(--clr-success-l) * 1.8));
}
.flash-info {
  color: var(--clr-info-dark);
}
.flash-info-box {
  color: var(--clr-info-dark);
  border: 1px solid var(--clr-info-dark);
  background-color: hsl(var(--clr-info-h), var(--clr-info-s), calc(var(--clr-info-l) * 1.8));
}
.flash-warning {
  color: var(--clr-warning-dark);
}
.flash-warning-box {
  color: var(--clr-warning-dark);
  border: 1px solid var(--clr-warning-dark);
  background-color: hsl(var(--clr-warning-h), var(--clr-warning-s), calc(var(--clr-warning-l) * 1.8));
}
.flash-error {
  color: var(--clr-error-dark);
}
.flash-error-box {
  color: var(--clr-error-dark);
  border: 1px solid var(--clr-error-dark);
  background-color: hsl(var(--clr-error-h), var(--clr-error-s), calc(var(--clr-error-l) * 2));
}
.flash-enlightenment {
  color: var(--clr-neutral-grey-700);
}
.flash-enlightenment-box {
  color: var(--clr-neutral-grey-800);
  border: 1px solid var(--clr-neutral-grey-300);
  background-color: hsl(var(--clr-neutral-grey-200-h), var(--clr-neutral-grey-200-s), calc(var(--clr-neutral-grey-200-l) * 1.8));
}
.flash-enlightenment-light {
  color: var(--clr-neutral-white);
}
.flash-enlightenment-light-box {
  color: var(--clr-neutral-grey-800);
  border: 1px solid var(--clr-neutral-grey-300);
  background-color: var(--clr-neutral-white);
}

.auth {
  position: relative;
}
.auth .flash {
  position: absolute;
  top: 0;
}

.description-list {
  width: 100%;
  font-size: 1.4rem;
}
.description-list dt {
  font-weight: 600;
  padding: 0 1rem 0 0.5rem;
}
.description-list dd {
  margin-inline-start: 0;
  padding: 0 1rem 0.5rem 0.5rem;
  border-bottom: 1px solid var(--clr-neutral-grey-200);
}
@media only screen and (min-width: 45em) {
  .description-list dd {
    margin-right: 3rem;
  }
}

.navbar {
  list-style-type: none;
  display: flex;
  align-items: center;
  line-height: 1.4;
}
.navbar a, .navbar i, .navbar form button, .navbar form button i {
  color: var(--clr-primary-lighter);
  margin: 0 0.4rem;
  transition: all 0.3s ease;
}
.navbar a:hover, .navbar a.active, .navbar i:hover, .navbar i.active, .navbar form button:hover, .navbar form button.active, .navbar form button i:hover, .navbar form button i.active {
  color: var(--clr-neutral-white);
}
.navbar a {
  position: relative;
  text-decoration: none;
  font-size: 2.2rem;
}
.navbar i, .navbar form button i {
  font-size: 2.5rem;
}
.navbar form button {
  background-color: transparent;
}
.navbar-message {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 2rem;
  height: 2rem;
  top: -0.4rem;
  left: 2rem;
  font-size: 1.2rem;
  font-weight: 500;
  border-radius: 50%;
  background-color: var(--clr-success);
  color: var(--clr-neutral-white);
}
.navbar-btn {
  padding: 0.4rem 1.5rem;
  border-radius: 10.5rem;
  margin-left: 1rem;
  font-size: 1.6rem !important;
  transition: all 0.3s ease;
  border: 1px solid var(--clr-neutral-white);
}
.navbar-btn button {
  color: var(--clr-neutral-white) !important;
}
.navbar-btn:hover {
  background-color: var(--clr-info-darker);
}

body#home a#home i,
body#messages a#messages i {
  color: var(--clr-neutral-white);
}

.tooltip {
  position: relative;
  cursor: help;
  background-image: linear-gradient(to right, var(--clr-neutral-grey-200) 33%, rgba(255, 255, 255, 0) 0%);
  background-position: bottom;
  background-size: 4px 1px;
  background-repeat: repeat-x;
}
.tooltip::after {
  background-color: var(--clr-neutral-grey-900);
  border-radius: 1rem;
  color: var(--clr-neutral-white);
  display: none;
  padding: 0.6rem 1rem;
  position: absolute;
  text-align: center;
  z-index: 999;
  width: auto;
  font-size: 1.2rem;
  line-height: 1.2;
}
.tooltip-top::after {
  width: 100%;
  max-width: 20rem;
  content: attr(data-text);
  top: 0;
  left: 50%;
  transform: translate(-50%, calc(-100% - 10px));
}
.tooltip-bottom::after {
  width: 100%;
  max-width: 20rem;
  content: attr(data-text);
  bottom: 0;
  left: 50%;
  transform: translate(-50%, calc(100% + 10px));
}
.tooltip-right::after {
  content: attr(data-text);
  top: -0.75rem;
  right: 0;
  transform: translateX(calc(100% + 10px));
}
.tooltip-left::after {
  content: attr(data-text);
  top: -0.75rem;
  left: 0;
  transform: translateX(calc(-100% - 10px));
}
.tooltip:hover::after {
  display: block;
}
.tooltip::before {
  background-color: var(--clr-neutral-grey-900);
  content: " ";
  display: none;
  position: absolute;
  width: 1.5rem;
  height: 1.5rem;
  z-index: 999;
}
.tooltip-top::before {
  top: 0;
  left: 50%;
  transform: translate(-50%, calc(-100% - 0.5rem)) rotate(45deg);
}
.tooltip-bottom::before {
  bottom: 0;
  left: 50%;
  transform: translate(-50%, calc(100% + 0.5rem)) rotate(45deg);
}
.tooltip-right::before {
  top: -0.15rem;
  right: -1.2rem;
  width: 0.75rem;
  aspect-ratio: 1;
  clip-path: polygon(100% 100%, 100% 0, 0 50%);
}
.tooltip-left::before {
  top: -0.15rem;
  left: -1.2rem;
  width: 0.75rem;
  aspect-ratio: 1;
  clip-path: polygon(0 0, 100% 50%, 0 100%);
}
.tooltip:hover::before, .tooltip:hover::after {
  display: block;
}

a > .tooltip, button > .tooltip {
  cursor: pointer;
  background-image: none;
  background-position: 0;
  background-size: 0;
  background-repeat: no-repeat;
}

.feedback {
  font-size: 1rem;
}
.feedback-input-error, .feedback-input-help {
  position: absolute;
  right: 0.3rem;
  bottom: -1.6rem;
}
.feedback-input-error, .feedback-error, .feedback-form-error {
  font-weight: 500;
  color: #a6192e;
}
.feedback-input-help {
  font-weight: 500;
  color: #04b34f;
}
.feedback-form-error {
  display: block;
  margin: 0.5rem 0.3rem 0 0.3rem;
  line-height: 1.2;
}
.feedback-error {
  position: absolute;
  left: 0.3rem;
  bottom: -1.6rem;
}

.modal {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: flex-start;
  z-index: 999;
  overflow: auto;
  background-color: hsla(var(--clr-neutral-black-h), var(--clr-neutral-black-s), var(--clr-neutral-black-l), 0.8);
  padding: 0 1.5rem;
}
.modal.modal-show {
  animation: fadeIn 0.2s ease-in-out forwards;
}
.modal.modal-hide {
  animation: fadeOut 0.2s ease-in-out forwards;
}
.modal-container {
  width: 100%;
  height: auto;
  position: relative;
  background-color: var(--clr-neutral-white);
  margin: 6rem 0 0 0;
  padding: 1rem 1.5rem;
  border-radius: 0.5rem;
  overflow: auto;
  box-shadow: 0.175rem 0.175rem 0.26rem hsla(var(--clr-neutral-black-h), var(--clr-neutral-black-s), var(--clr-neutral-black-l), 0.15);
}
@media only screen and (min-width: 45em) {
  .modal-container {
    margin: 6rem auto;
    max-width: 50rem;
  }
}
.modal-container form {
  margin-top: 1.5rem;
}
.modal header {
  padding: 0 1rem 0 1rem;
  border-bottom: 1px solid var(--clr-neutral-grey-200);
}
.modal-title {
  margin: 0 0 0.5rem 0;
}
.modal-content {
  padding: 0.5rem 1rem 1rem 1rem;
}
.modal-content > * {
  font-size: 1.4rem;
}
.modal-likes {
  position: relative;
  display: flex;
  align-items: center;
  margin: 0.5rem 1.5rem 0 0;
  padding: 0 0 0.5rem 0;
}
.modal-likes img {
  border-radius: 50%;
}
.modal-likes-thumb {
  background-color: var(--clr-info);
  position: absolute;
  display: flex;
  left: 1.5rem;
  bottom: 0;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
}
.modal-likes-thumb i {
  font-size: 1.2rem;
}
.modal-likes-user {
  width: 100%;
  padding-left: 1rem;
  align-items: center;
  line-height: 1.5rem;
  border-bottom: 1px solid var(--clr-neutral-grey-200);
}
.modal-likes-user span {
  display: block;
}
.modal-likes-user span:first-child {
  font-size: 1.2rem;
  font-weight: 500;
}
.modal-likes-user span:nth-child(2) {
  font-size: 1rem;
  font-weight: 500;
}
.modal-likes-user a {
  position: absolute;
  top: 0;
  right: 1rem;
  padding: 0.4rem 1.2rem;
  border-radius: 0.5rem;
  color: white;
  background-color: var(--clr-info);
  transition: all 0.3s ease;
}
.modal-likes-user a i {
  font-size: 1.8rem;
}
.modal-likes-user a:hover {
  background-color: var(--clr-info-dark);
}
.modal footer {
  padding: 0 1rem 1rem 1rem;
  border-top: 1px solid var(--clr-neutral-grey-200);
}
.modal footer > * {
  font-size: 1.6rem;
}
.modal-close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  font-size: 2rem;
  padding: 0 0.75rem;
  cursor: pointer;
  user-select: none;
  color: hsla(var(--clr-neutral-black-h), var(--clr-neutral-black-s), var(--clr-neutral-black-l), 0.3);
  transition: all 0.2s ease;
}
.modal-close:hover, .modal-close:focus {
  color: hsla(var(--clr-neutral-black-h), var(--clr-neutral-black-s), var(--clr-neutral-black-l), 0.6);
}
.modal.modal-show .modal-container {
  animation: fadeInDown 0.3s ease-in-out forwards;
}
.modal.modal-hide .modal-container {
  animation: fadeOutUp 0.3s ease-in-out forwards;
}

#cookies-modal .modal-container {
  width: 100%;
  max-width: 80rem;
  max-height: 80%;
  padding-bottom: 1rem;
  overflow: auto;
}
#cookies-modal .modal-container::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
#cookies-modal .modal-container::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 0.5rem;
  border-radius: 0.5rem;
}
#cookies-modal .modal-container::-webkit-scrollbar-thumb {
  -webkit-border-radius: 1rem;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.4);
}
#cookies-modal .modal-container::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(255, 255, 255, 0.3);
}

.searchbar-container {
  background-color: var(--clr-primary);
  z-index: 1;
  width: 100%;
  position: fixed;
  top: -6rem;
  transition: all 0.3s ease-in-out;
}
.searchbar-container.active {
  top: 6rem;
  height: auto;
}
.searchbar-container:has(.search-results.active) {
  padding-bottom: 1.5rem;
}
@media only screen and (min-width: 45em) {
  .searchbar {
    margin: 0 0 0 4rem;
  }
}
@media only screen and (min-width: 60em) {
  .searchbar {
    margin: 0;
    padding: 0 3rem 0 20rem;
    max-width: 156.6rem;
  }
}
.searchbar form {
  height: 100%;
  position: relative;
  display: flex;
  margin: 0 1rem;
  padding: 0.5rem 0 1.5rem 0;
  align-items: center;
}
@media only screen and (min-width: 60em) {
  .searchbar form {
    margin: 0;
  }
}
.searchbar form input {
  border-radius: 1rem;
  height: 4rem;
  background-color: var(--clr-neutral-white);
  padding-right: 5rem;
  padding-left: 2rem;
}
.searchbar form button {
  position: absolute;
  top: 0.8rem;
  right: 0.5rem;
  padding: 0.6rem 1rem 0.4rem 1rem;
  background-color: transparent;
}
.searchbar form button i {
  color: var(--clr-neutral-grey-300);
  font-size: 2rem;
}
.searchbar .search-results {
  background-color: var(--clr-neutral-white);
  border-radius: 1rem;
  display: none;
  padding: 1rem;
  margin: 0 1rem;
}
@media only screen and (min-width: 45em) {
  .searchbar .search-results {
    padding: 1rem 2.5rem;
  }
}
@media only screen and (min-width: 45em) {
  .searchbar .search-results {
    padding: 1rem 3rem;
  }
}
@media only screen and (min-width: 60em) {
  .searchbar .search-results {
    margin: 0;
  }
}
.searchbar .search-results.active {
  display: block;
}

.search-result {
  background-color: var(--clr-neutral-white);
}
.search-result span i {
  margin-left: 0.5rem;
  font-size: 1rem;
  color: var(--clr-success);
}
.search-result span i.offline {
  color: var(--clr-neutral-grey-300);
}

.emoji-container {
  position: absolute;
  display: none;
  background-color: var(--clr-neutral-white);
  top: 6.5rem;
  right: 0;
  max-width: 30rem;
  border: 1px solid var(--clr-neutral-grey-300);
  border-radius: 1rem;
  z-index: 1;
  flex-wrap: wrap;
  align-items: center;
  padding: 0.5rem 1rem;
  transition: all 0.2s ease-in-out;
}
.emoji-container .emoji {
  cursor: pointer;
  width: 2.75rem;
  height: 2.75rem;
  text-align: center;
}
.emoji-container .emoji:hover {
  background-color: var(--clr-neutral-grey-200);
  border-radius: 50%;
}

.pagination {
  margin-top: 5rem;
  max-width: 100%;
}
.pagination-rows, .pagination ul {
  display: flex;
  width: auto;
  justify-content: center;
  align-items: center;
}
.pagination-rows {
  font-size: 1.2rem;
  font-weight: bold;
}
.pagination-info {
  display: flex;
  justify-content: space-between;
  margin: 0.5rem 0.5rem 0 0.5rem;
}
@media only screen and (min-width: 45em) {
  .pagination ul {
    margin-top: 3rem;
  }
}
.pagination li {
  position: absolute;
  top: -9999px;
  left: -9999px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--clr-info);
  color: var(--clr-neutral-white);
  border: 1px solid var(--clr-info);
  transition: all 0.3s ease-out;
  height: 3.6rem;
  min-width: 3.6rem;
}
.pagination li:not(:last-child) {
  margin-right: 0.4rem;
}
@media only screen and (min-width: 45em) {
  .pagination li {
    position: unset;
    height: 100%;
  }
}
.pagination li:first-of-type, .pagination li.active, .pagination li:last-of-type, .pagination li:nth-of-type(2), .pagination li:nth-last-of-type(2) {
  position: initial;
  top: initial;
  left: initial;
}
.pagination li:first-child {
  border-left: 1px solid var(--clr-info);
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}
.pagination li:last-child {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}
.pagination li.active {
  background-color: var(--clr-info-darker);
  color: var(--clr-neutral-white);
  border: 1px solid var(--clr-info);
}
.pagination li:hover {
  background-color: var(--clr-info-darker);
}
.pagination li:hover a {
  color: var(--clr-neutral-white);
}
.pagination a, .pagination span {
  display: inline-block;
  text-decoration: none;
  padding: 0.5rem 1rem;
}
.pagination a, .pagination a:active {
  color: var(--clr-neutral-white);
}
.pagination-page-of-pages {
  margin-top: 1rem;
  font-size: 1.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--clr-neutral-grey-800);
}
.pagination-info .pagination-page-of-pages {
  font-size: 1.2rem;
  font-weight: bold;
  margin-top: 0;
}

.cookies article ul {
  list-style: none;
  padding-top: 1rem;
}
.cookies article ul li {
  padding: 0.2rem 0;
  transition: all 0.2s ease;
}
.cookies > * a:hover {
  text-decoration: none;
}

#cookies {
  padding: 0.5rem 0;
  width: 100%;
  position: fixed;
  bottom: 0;
  color: var(--clr-neutral-white);
  background-color: hsla(var(--clr-neutral-black-h), var(--clr-neutral-black-s), var(--clr-neutral-black-l), 0.8);
  z-index: 998;
  transform: translateY(100%);
  transition: transform 0.5s; /* 2s for demo only */
}
#cookies.show {
  display: block;
  transform: translateY(0);
}
#cookies.hide {
  display: none;
  transform: translateY(100%);
}
#cookies .cookie-consent {
  min-height: 4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
}
#cookies .cookie-consent p {
  font-size: 1.4rem;
}
#cookies .cookie-consent a {
  text-decoration: underline;
  color: var(--clr-neutral-grey-200);
  font-size: 1.4rem;
  transition: all 0.3s ease;
}
#cookies .cookie-consent a:hover {
  text-decoration: none;
  color: var(--clr-neutral-white);
}
#cookies .cookie-btn {
  padding: 0 1rem;
  border-radius: 0.5rem;
  color: var(--clr-neutral-grey-900);
  background-color: var(--clr-neutral-white);
  transition: all 0.2s ease;
}
#cookies .cookie-btn:hover {
  background-color: var(--clr-neutral-grey-200);
}

.cookie-read-btn {
  background-color: transparent;
  transition: color 0.3s ease;
  color: var(--clr-neutral-grey-200);
}
.cookie-read-btn:hover {
  color: var(--clr-neutral-white);
  text-decoration: underline;
}

.auth .cookie-read-btn {
  color: var(--clr-neutral-white);
}

.footer .cookie-read-btn {
  color: inherit;
  font-weight: 500;
}

.container {
  width: 100%;
  max-width: 136.6rem;
  margin: 0 auto;
  padding: 0 1rem;
}
@media only screen and (min-width: 45em) {
  .container {
    padding: 0 1.5rem;
  }
}
@media only screen and (min-width: 60em) {
  .container {
    padding: 0 3rem;
  }
}
.container:has(.sidebar) {
  position: relative;
  width: 100%;
  display: flex;
  margin: 0;
  padding: 0 0 5rem 0;
}
@media only screen and (min-width: 60em) {
  .container:has(.sidebar) {
    padding: 0 3rem 5rem 0;
    max-width: 136.6rem;
  }
}
.header .container {
  position: relative;
  width: 100%;
  max-width: 136.6rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 999;
  padding: 1rem;
  margin: 0;
}
@media only screen and (min-width: 60em) {
  .header .container {
    padding: 1rem 3rem;
  }
}
.container main {
  margin-top: 6rem;
  flex: 1;
}
@media only screen and (min-width: 45em) {
  .container main {
    max-width: 131.6rem;
    margin-left: 5rem;
  }
}
@media only screen and (min-width: 60em) {
  .container main {
    max-width: 136.6rem;
    margin-left: 20rem;
  }
}
.footer .container {
  max-width: 136.6rem;
  margin: 0;
}
.container + .footer .container {
  padding-left: 0;
}
@media only screen and (min-width: 45em) {
  .container + .footer .container {
    width: auto;
    max-width: 131.6rem;
    margin-left: 5rem;
  }
}
@media only screen and (min-width: 60em) {
  .container + .footer .container {
    max-width: 116.6rem;
    margin-left: 20rem;
  }
}

.header {
  position: fixed;
  width: 100%;
  background-color: var(--clr-primary);
  z-index: 999;
}
.header-brand {
  text-decoration: none;
  font-size: 2.5rem;
  color: var(--clr-neutral-white);
}
.header-brand span {
  color: var(--clr-neutral-grey-800);
}

.main-content {
  width: 100%;
  margin-top: 0.5rem;
}
@media only screen and (min-width: 45em) {
  .main-content {
    padding: 0 1rem 0 1.5rem;
  }
}
@media only screen and (min-width: 60em) {
  .main-content {
    min-width: 50rem;
    width: 100%;
    padding: 0 0 0 3rem;
  }
}

.container:has(.menus) .main-content {
  width: 100%;
}
@media only screen and (min-width: 45em) {
  .container:has(.menus) .main-content {
    padding: 0 1.5rem;
  }
}
@media only screen and (min-width: 60em) {
  .container:has(.menus) .main-content {
    min-width: 50rem;
    width: 100%;
    padding: 0 3rem;
  }
}

.main-content.admin {
  padding: 0 1rem;
}
@media only screen and (min-width: 45em) {
  .main-content.admin {
    padding: 0 1rem 0 1.5rem;
  }
}
@media only screen and (min-width: 60em) {
  .main-content.admin {
    padding: 0 0 0 3rem;
  }
}

.feed, .message {
  position: relative;
  width: 100%;
  border-bottom: 2px solid var(--clr-neutral-grey-200);
  padding: 1rem 0 0.5rem 0;
}
.feed-auth, .message-auth {
  padding: 0 0 1.5rem 0;
  margin: 1rem 0 0 0;
  display: flex;
}
.feed-auth img, .message-auth img {
  margin-left: 1rem;
  margin-top: 0.5rem;
  border-radius: 50%;
}
.feed-auth form, .message-auth form {
  position: relative;
  width: 100%;
  display: flex;
  margin-left: 1rem;
}
.feed-auth form textarea, .message-auth form textarea {
  flex: 1;
  padding: 0.2rem 1rem 0.4rem 1rem;
  border: 1px solid var(--clr-neutral-grey-200);
  border-radius: 1rem;
  resize: none;
}
.feed-auth form textarea::-webkit-scrollbar, .message-auth form textarea::-webkit-scrollbar {
  display: none;
}
.feed-auth form .form-group, .message-auth form .form-group {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.feed-auth form .form-group-feed, .feed-auth form .form-group-message, .message-auth form .form-group-feed, .message-auth form .form-group-message {
  position: relative;
  width: 100%;
  display: flex;
}
.feed-auth form input[type=file] + label,
.feed-auth form select + label, .message-auth form input[type=file] + label,
.message-auth form select + label {
  padding: 0 1rem;
  font-size: 2rem;
  cursor: pointer;
}
.feed-auth form input[type=file] + label, .message-auth form input[type=file] + label {
  color: var(--clr-success);
}
.feed-auth form select, .message-auth form select {
  position: absolute;
  top: -6rem;
  right: 5rem;
  width: auto;
  opacity: 0;
  border-radius: 1rem;
  padding: 0.5rem 1rem;
  transition: 0.3s all ease;
}
.feed-auth form select + label, .message-auth form select + label {
  color: var(--clr-info);
}
.feed-auth form select.active, .message-auth form select.active {
  opacity: 1;
  top: 0.5rem;
}
.feed-auth form button, .message-auth form button {
  background-color: transparent;
  color: var(--clr-info);
}
.feed header, .message header {
  padding: 0 1rem;
  display: flex;
  justify-content: space-between;
}
.feed-user, .message-user {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
}
.feed-user img, .message-user img {
  border-radius: 50%;
}
.feed-user-info, .message-user-info {
  margin-left: 0.5rem;
  display: inline-block;
}
.feed-user-info span, .message-user-info span {
  display: block;
  line-height: 1.4;
  font-weight: 500;
}
.feed-user-info span:first-child, .message-user-info span:first-child {
  font-size: 1.2rem;
}
.feed-user-info span:first-child i, .message-user-info span:first-child i {
  font-size: 1.4rem;
}
.feed-user-info span:last-child, .message-user-info span:last-child {
  font-size: 0.8rem;
}
.feed-close, .message-close {
  position: absolute;
  top: -0.5rem;
  right: 0;
  color: var(--clr-neutral-grey-600);
  transition: all 0.3s;
  cursor: pointer;
}
.feed-close:hover, .message-close:hover {
  color: var(--clr-neutral-grey-900);
}
.feed-body, .message-body {
  padding: 0.5rem 0 0 0;
}
.feed-body p, .message-body p {
  padding: 0 1rem;
  font-size: 1.4rem;
}
.feed-info, .message-info {
  display: flex;
  justify-content: space-between;
}
.feed-info a, .feed-info span, .feed-info button, .message-info a, .message-info span, .message-info button {
  text-decoration: none;
  display: block;
  padding: 0.5rem 1.2rem;
  font-size: 1.2rem;
  color: inherit;
}
.feed-info a i, .feed-info span i, .feed-info button i, .message-info a i, .message-info span i, .message-info button i {
  margin-right: 0.2rem;
  color: var(--clr-info);
}
.feed-image, .message-image {
  margin-top: 0.5rem;
  width: 100%;
  height: auto;
  text-align: center;
  background-color: var(--clr-neutral-grey-900);
}
.feed footer, .message footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1.2rem 0 1.2rem;
  border-top: 1px solid var(--clr-neutral-grey-200);
  color: var(--clr-neutral-grey-800);
}
.feed footer a, .feed footer span, .message footer a, .message footer span {
  text-decoration: none;
  color: inherit;
  font-size: 1.2rem;
}
.feed footer a i, .feed footer span i, .message footer a i, .message footer span i {
  margin-right: 0.3rem;
}
.feed footer form, .feed footer button, .message footer form, .message footer button {
  color: inherit;
  font-size: 1.2rem;
}
.feed footer form i, .feed footer button i, .message footer form i, .message footer button i {
  margin-right: 0.3rem;
}
.feed-comments, .message-comments {
  height: 100%;
  max-height: 0;
  padding: 0 1rem;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
}
.feed-comments.active, .message-comments.active {
  margin-top: 0.3rem;
  padding: 1rem 1rem 0.5rem 1rem;
  display: block;
  max-height: 50rem;
  overflow: auto;
}
.feed-comments.active::-webkit-scrollbar, .message-comments.active::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}
.feed-comments.active::-webkit-scrollbar-track, .message-comments.active::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 0.5rem;
  border-radius: 0.5rem;
}
.feed-comments.active::-webkit-scrollbar-thumb, .message-comments.active::-webkit-scrollbar-thumb {
  -webkit-border-radius: 1rem;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.4);
}
.feed-comments.active::-webkit-scrollbar-thumb:window-inactive, .message-comments.active::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(255, 255, 255, 0.3);
}
.feed-comments.active::-webkit-scrollbar, .message-comments.active::-webkit-scrollbar {
  display: none;
}
.feed-comment:not(:last-child), .message-comment:not(:last-child) {
  margin-bottom: 0.5em;
}
.feed-comment img, .message-comment img {
  border-radius: 50%;
  margin-right: 0.5rem;
}
.feed-comment-group, .message-comment-group {
  display: flex;
}
.feed-comment-body, .message-comment-body {
  padding: 0 1rem;
  border-radius: 1rem;
  line-height: 1.3;
  background-color: hsla(var(--clr-neutral-grey-200-h), var(--clr-neutral-grey-200-s), var(--clr-neutral-grey-200-l), 0.3);
}
.feed-comment-body span, .message-comment-body span {
  font-size: 1.2rem;
  font-weight: 500;
}
.feed-comment-body p, .message-comment-body p {
  font-size: 1.2rem;
  padding: 0 0 0.5rem 0;
}
.feed-comment-action, .message-comment-action {
  font-size: 1.2rem;
  margin-left: 4.5rem;
}
.feed-comment-action a, .message-comment-action a {
  margin-right: 0.5rem;
  text-decoration: none;
}
.feed-comment-action a i, .message-comment-action a i {
  margin-right: 0.2rem;
}
.feed-comment-action span i, .message-comment-action span i {
  margin-right: 0.2rem;
}
.feed-comment-action form, .message-comment-action form {
  display: inline;
  margin-right: 0.5rem;
}
.feed-comment-action form button i, .message-comment-action form button i {
  margin-right: 0.2rem;
}
.feed-comment-auth, .message-comment-auth {
  height: 100%;
  max-height: 0;
  display: flex;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
}
.feed-comment-auth.active, .message-comment-auth.active {
  margin: 1rem 0 0 0;
  padding: 0 0 1rem 0;
  max-height: 20rem;
  overflow: auto;
}
.feed-comment-auth.active::-webkit-scrollbar, .message-comment-auth.active::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}
.feed-comment-auth.active::-webkit-scrollbar-track, .message-comment-auth.active::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 0.5rem;
  border-radius: 0.5rem;
}
.feed-comment-auth.active::-webkit-scrollbar-thumb, .message-comment-auth.active::-webkit-scrollbar-thumb {
  -webkit-border-radius: 1rem;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.4);
}
.feed-comment-auth.active::-webkit-scrollbar-thumb:window-inactive, .message-comment-auth.active::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(255, 255, 255, 0.3);
}
.feed-comment-auth.active::-webkit-scrollbar, .message-comment-auth.active::-webkit-scrollbar {
  display: none;
}
.feed-comment-auth img, .message-comment-auth img {
  margin-left: 1rem;
  margin-top: 0.5rem;
  border-radius: 50%;
}
.feed-comment-auth form, .message-comment-auth form {
  width: 100%;
  display: flex;
  margin-left: 1rem;
  margin-right: 1rem;
}
.feed-comment-auth form textarea, .message-comment-auth form textarea {
  overflow: auto;
  flex: 1;
  margin-right: 1rem;
  padding: 0.2rem 1rem 0.4rem 1rem;
  border: 1px solid var(--clr-neutral-grey-200);
  border-radius: 1rem;
  resize: none;
}
.feed-comment-auth form textarea::-webkit-scrollbar, .message-comment-auth form textarea::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}
.feed-comment-auth form textarea::-webkit-scrollbar-track, .message-comment-auth form textarea::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 0.5rem;
  border-radius: 0.5rem;
}
.feed-comment-auth form textarea::-webkit-scrollbar-thumb, .message-comment-auth form textarea::-webkit-scrollbar-thumb {
  -webkit-border-radius: 1rem;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.4);
}
.feed-comment-auth form textarea::-webkit-scrollbar-thumb:window-inactive, .message-comment-auth form textarea::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(255, 255, 255, 0.3);
}
.feed-comment-auth form textarea::-webkit-scrollbar, .message-comment-auth form textarea::-webkit-scrollbar {
  display: none;
}
.feed-comment-auth form .form-group, .message-comment-auth form .form-group {
  display: flex;
  flex-direction: column;
}
.feed-comment-auth form .form-group-feed, .feed-comment-auth form .form-group-message, .message-comment-auth form .form-group-feed, .message-comment-auth form .form-group-message {
  width: 100%;
  display: flex;
}
.feed-comment-auth form input[type=file] + label, .message-comment-auth form input[type=file] + label {
  padding: 0 1rem;
  font-size: 2rem;
  color: var(--clr-success);
  cursor: pointer;
}
.feed-comment-auth form button, .message-comment-auth form button {
  background-color: transparent;
  color: var(--clr-info);
}

.feed-comment-auth.active .emoji-container,
.message-comment-auth.active .emoji-container {
  position: absolute;
  top: auto;
  right: 4rem;
  bottom: 8rem;
}

.feed:has(.feed-comments.active) .feed-comment-auth.active .emoji-container,
.feed:has(.feed-comments.active) .message-comment-auth.active .emoji-container,
.message:has(.message-comments.active) .feed-comment-auth.active .emoji-container,
.message:has(.message-comments.active) .message-comment-auth.active .emoji-container {
  position: absolute;
  top: auto;
  right: 4rem;
  bottom: 16rem;
}

.message-auth .form-group-message .emoji-container {
  top: 9.5rem;
}

.sidebar {
  width: 100%;
  position: fixed;
  bottom: 0;
  z-index: 998;
  background-color: var(--clr-primary);
}
@media only screen and (min-width: 45em) {
  .sidebar {
    top: 6rem;
    bottom: -4rem;
    height: 100%;
    min-height: calc(100vh - 6rem);
    width: 5rem;
    padding: 0 1rem;
  }
}
@media only screen and (min-width: 60em) {
  .sidebar {
    width: 20rem;
    padding: 0 3rem;
  }
}
.sidebar nav {
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 5rem;
}
@media only screen and (min-width: 45em) {
  .sidebar nav {
    margin-top: 2rem;
    display: block;
  }
}
.sidebar nav a, .sidebar nav button {
  background-color: transparent;
  width: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: var(--clr-info-lighter);
  transition: all 0.3s ease;
}
.sidebar nav a span, .sidebar nav button span {
  text-align: center;
  margin-top: 0.3rem;
  display: block;
  font-size: 1rem;
  line-height: 1;
}
@media only screen and (min-width: 45em) {
  .sidebar nav a span, .sidebar nav button span {
    display: none;
  }
}
@media only screen and (min-width: 60em) {
  .sidebar nav a span, .sidebar nav button span {
    display: flex;
  }
}
@media only screen and (min-width: 45em) {
  .sidebar nav a, .sidebar nav button {
    margin-bottom: 1.5rem;
  }
  .sidebar nav a i, .sidebar nav button i {
    font-size: 2rem;
  }
}
@media only screen and (min-width: 60em) {
  .sidebar nav a, .sidebar nav button {
    width: 100%;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 0.5rem;
    padding: 0.5rem 0;
  }
  .sidebar nav a i, .sidebar nav button i {
    width: 3rem;
    text-align: center;
  }
  .sidebar nav a span, .sidebar nav button span {
    margin-left: 1rem;
    font-size: 1.4rem;
  }
}
.sidebar nav a:hover, .sidebar nav a span:hover, .sidebar nav button:hover, .sidebar nav button span:hover {
  color: var(--clr-neutral-white);
}
@media only screen and (min-width: 60em) {
  .sidebar nav button span {
    margin-top: 0;
  }
}
@media only screen and (min-width: 60em) {
  .sidebar nav button {
    display: none;
    visibility: hidden;
  }
}

body#feeds .sidebar nav a#feeds,
body#user .sidebar nav a#user,
body#photo-album .sidebar nav a#photo-album,
body#dashboard .sidebar nav a#dashboard,
body#new-user .sidebar nav a#new-user,
body#users .sidebar nav a#users,
body#failed-logins .sidebar nav a#failed-logins,
body#contacts .sidebar nav a#contacts {
  color: var(--clr-neutral-white);
}

.menus {
  width: 100%;
  position: fixed;
  bottom: 0;
  height: auto;
}
@media only screen and (min-width: 45em) {
  .menus {
    top: 0.5rem;
    bottom: auto;
  }
}
@media only screen and (min-width: 60em) {
  .menus {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: 30rem;
    bottom: auto;
    max-width: 30rem;
    margin: 7rem 0 0 0;
    height: auto;
  }
}

.menu {
  position: fixed;
  width: auto;
  max-width: 100%;
  left: 1rem;
  right: 1rem;
  margin: 1rem 1rem 0 1rem;
  padding: 1rem;
  border-radius: 0.5rem;
  border: 1px solid var(--clr-neutral-grey-200);
  background-color: var(--clr-neutral-white);
  bottom: -60rem;
  transition: all 0.3s ease-in-out;
}
@media only screen and (min-width: 45em) {
  .menu {
    max-width: 40rem;
    top: 6rem;
    bottom: auto;
    left: -60rem;
  }
}
.menu.active {
  position: relative;
  left: 0;
  right: 0;
  bottom: 6rem;
}
@media only screen and (min-width: 45em) {
  .menu.active {
    top: 6rem;
    bottom: auto;
    left: 5rem;
  }
}
.menu-title {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
.menu-title a {
  color: var(--clr-info);
  text-decoration: none;
  font-size: 1.2rem;
  margin-right: 1rem;
}
.menu-title a:hover {
  color: var(--clr-info-dark);
}
.menu h6 {
  padding: 0 1rem;
}
@media only screen and (min-width: 60em) {
  .menu {
    position: relative;
    top: 0;
    right: 0;
    bottom: auto;
    left: auto;
    display: block;
    margin: 1rem 0;
    padding: 0.5rem 0 0 0;
  }
}
.menu-container {
  max-height: 20rem;
  overflow: auto;
}
.menu-container::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}
.menu-container::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 0.5rem;
  border-radius: 0.5rem;
}
.menu-container::-webkit-scrollbar-thumb {
  -webkit-border-radius: 1rem;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.4);
}
.menu-container::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(255, 255, 255, 0.3);
}
.menu header {
  padding: 0.5rem 1rem;
}
.menu header > span {
  font-size: 1rem;
  font-weight: 500;
}
.menu-user {
  display: flex;
  align-items: center;
  font-size: 1.4rem;
}
.menu-user img {
  border-radius: 50%;
  margin-right: 0.5rem;
}
.menu-navbar {
  display: flex;
  justify-content: flex-start;
  padding: 0.5rem 1rem 1rem 1rem;
}
@media only screen and (min-width: 45em) {
  .menu-navbar {
    flex-wrap: wrap;
  }
}
@media only screen and (min-width: 60em) {
  .menu-navbar {
    justify-content: space-between;
  }
}
.menu-navbar ul {
  list-style-type: none;
  margin-right: 2rem;
}
.menu-navbar ul li {
  line-height: 1.3;
}
.menu-navbar ul li a {
  color: var(--clr-info);
  text-decoration: none;
  font-size: 1.4rem;
}
.menu-navbar ul li a:hover {
  color: var(--clr-info-dark);
}
.menu-friend {
  padding: 0.5rem 0 0.5rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid var(--clr-neutral-grey-200);
}
.menu-friend img {
  border-radius: 50%;
}
.menu-info {
  margin-left: 0.75rem;
}
.menu-info span {
  display: block;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.2;
}
.menu-info span.status {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 5.2rem;
}
.menu-info span.status i {
  margin-left: 0.5rem;
  font-size: 1rem;
  color: var(--clr-success);
}
.menu-info span.status i.offline {
  color: var(--clr-neutral-grey-300);
}
.menu-content {
  display: flex;
  align-items: center;
}
.menu-content p {
  font-size: 1.2rem;
}
.menu-action {
  display: flex;
  padding: 0 1rem;
}
.menu-action a, .menu-action button {
  margin-left: 0.75rem;
}
.menu-action a i, .menu-action button i {
  font-size: 1.8rem;
}
.menu-action a i {
  color: var(--clr-info);
}
.menu-action button i {
  color: var(--clr-success);
}

.friends {
  width: 100%;
  padding: 0 1rem;
}
@media only screen and (min-width: 45em) {
  .friends {
    padding: 0 1.5rem;
  }
}
@media only screen and (min-width: 60em) {
  .friends {
    width: 100%;
    min-width: 50rem;
    padding: 0 3rem;
  }
}

.friend {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--clr-neutral-grey-200);
}
@media only screen and (min-width: 60em) {
  .friend {
    width: 100%;
    min-width: 50rem;
  }
}
.friend-content {
  padding: 0.75rem 0 0.75rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.friend-content img {
  border-radius: 50%;
}
.friend-info {
  margin-left: 0.75rem;
}
.friend-info span {
  display: block;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.2;
}
.friend-info span.status {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 5.2rem;
}
.friend-info span.status i {
  margin-left: 0.5rem;
  font-size: 1rem;
  color: var(--clr-success);
}
.friend-info span.status i.offline {
  color: var(--clr-neutral-grey-300);
}
.friend-actions {
  display: flex;
  gap: 1rem;
  margin-right: 1rem;
}
.friend-request {
  position: relative;
}
.friend-request::after {
  position: absolute;
  top: 0;
  right: 1.45rem;
  height: 0.85rem;
  width: 0.85rem;
  border-radius: 50%;
  content: "";
  background-color: var(--clr-success);
}
@media only screen and (min-width: 45em) {
  .friend-request::after {
    top: -0.1;
    right: 1.4rem;
    height: 1.1rem;
    width: 1.1rem;
  }
}

.form {
  width: 100%;
  background-color: var(--clr-neutral-white);
}
.form :is(h1, h2, h3, h4, h5, h6) {
  margin-bottom: 0.7rem;
}
@media only screen and (min-width: 45em) {
  .form {
    max-width: 52rem;
  }
}
@media only screen and (min-width: 30em) {
  .form-row {
    display: flex;
    justify-content: space-between;
    gap: 2rem;
  }
}
.form-auth {
  width: calc(100vw - 3rem);
  padding: 0.5rem 2.5rem 2.5rem 2.5rem;
  border-radius: 0.5rem;
  border: 1px solid var(--clr-neutral-grey-200);
  margin: 0 1.5rem;
}
@media only screen and (min-width: 45em) {
  .form-auth {
    margin: 0;
    width: 40rem;
  }
}
.form :is(h1, h2, h3, h4, h5, h6) {
  margin-bottom: 0.7rem;
}
.form input:not(input[type=checkbox], input[type=radio]),
.form select, .form textarea, .form button, .form-btn, .form-btn-cancel {
  font-size: 1.5rem;
  border-radius: 0.5rem;
  line-height: 1.3;
}
.form input:not(input[type=checkbox], input[type=radio], input[type=file]),
.form select, .form textarea {
  padding: 0.75rem 1rem;
}
.form-group > i {
  position: absolute;
  right: 1rem;
  top: 70%;
  color: var(--clr-neutral-grey-300);
  cursor: pointer;
  transform: translateY(-50%);
}
.form-group > i.active::before {
  content: "\f06e";
  color: var(--clr-neutral-grey-700);
}
.form button, .form-btn, .form-btn-cancel {
  text-decoration: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.75rem 1.5rem;
  border-radius: 0.5rem;
  transition: all 0.3s ease;
  -webkit-appearance: none;
}
.form-search {
  position: relative;
  display: flex;
  align-items: center;
}
.form-search #search {
  border-radius: 0.5rem 0 0 0.5rem;
}
.form textarea {
  height: 100%;
  min-height: 10rem;
  resize: vertical;
}
.form-group {
  position: relative;
}
.form-group-inline {
  position: relative;
  display: flex;
  margin-bottom: 0.6rem;
}
.form-group-inline .form-label {
  font-size: 1.2rem;
  font-weight: 500;
  margin: 0 0 0 0.5rem;
}
@media only screen and (min-width: 30em) {
  .form-row .form-group {
    flex: 1;
  }
}
.form-label {
  position: relative;
  display: block;
  margin: 0.5rem 0 0 0.3rem;
  font-size: 1.5rem;
}
.form-label-sr-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.form-btn i {
  padding-right: 0.5rem;
}
.form button, .form-btn {
  background-color: var(--clr-neutral-grey-600);
  color: var(--clr-neutral-white);
  border: 1px solid var(--clr-neutral-grey-600);
}
.form button:hover, .form-btn:hover {
  background-color: var(--clr-neutral-grey-700);
  border: 1px solid var(--clr-neutral-grey-700);
}
.form-btn-cancel {
  background-color: var(--clr-neutral-white);
  color: var(--clr-neutral-grey-400);
  border: 1px solid var(--clr-neutral-grey-400);
}
.form-btn-cancel:hover {
  color: var(--clr-neutral-grey-700);
  border: 1px solid var(--clr-neutral-grey-700);
}
.form-search button {
  border-radius: 0 0.5rem 0.5rem 0;
}
.form-upload-btn {
  position: relative;
}
.form-upload-btn i {
  position: absolute;
  left: 0;
  font-size: 2rem;
  padding: 0.9rem 1.5rem;
  height: 100%;
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  background-color: var(--clr-neutral-grey-600);
  color: var(--clr-neutral-white);
}
.form-upload-btn > input[type=file] {
  width: 100%;
  position: relative;
  font-size: 1.4rem;
  color: var(--clr-neutral-grey-400);
  background-color: transparent;
  cursor: pointer;
  padding: 0.432rem 0;
}
.form-actions {
  position: relative;
  margin-top: 2rem;
}
.form-actions > :not(:first-child) {
  margin-left: 0.5rem;
}
.form-actions a {
  font-size: 1.5rem;
}

@media only screen and (min-width: 45em) {
  .contact {
    display: flex;
    flex-wrap: wrap;
    gap: 4rem;
  }
}
.contact-message, .contact-response {
  width: 100%;
}
.contact-message h6, .contact-response h6 {
  padding: 0 0.5rem;
}
@media only screen and (min-width: 45em) {
  .contact-message, .contact-response {
    flex: 1;
  }
}
.contact-message header, .contact-response header {
  margin-bottom: 1.3rem;
  border-top: 1px solid var(--clr-neutral-grey-200);
  border-bottom: 1px solid var(--clr-neutral-grey-200);
}
.contact-message header span, .contact-response header span {
  display: block;
  font-size: 1.2rem;
  font-weight: 700;
  padding: 0 0.5rem;
}
.contact-message-body, .contact-response-body {
  font-size: 1.4rem;
  padding: 0 0.5rem;
}
.contact-response {
  margin-top: 3rem;
}
@media only screen and (min-width: 45em) {
  .contact-response {
    margin-top: 0;
  }
  .contact-response .form {
    max-width: 100%;
  }
}
.contact-response .contact-message-body {
  margin-bottom: 1rem;
}
.contact-spam {
  display: inline-block;
  font-size: 1.3rem;
  font-weight: 500;
  background-color: var(--clr-error);
  color: var(--clr-neutral-white);
  padding: 0 0.75rem;
  margin-bottom: 1rem;
}
.contact-new-message {
  font-size: 1.3rem;
  display: inline-block;
  background-color: var(--clr-success-dark);
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  text-align: center;
  color: var(--clr-neutral-white);
}

.table {
  width: 100%;
  border-collapse: collapse;
  background-color: var(--clr-neutral-white);
  color: var(--clr-neutral-grey-700);
  border: 1px solid var(--clr-neutral-grey-200);
}
.table caption,
.table th,
.table td {
  padding: 0.75rem;
}
.table caption,
.table th {
  text-align: left;
}
.table caption {
  background-color: var(--clr-neutral-grey-600);
  color: var(--clr-neutral-white);
  font-size: 2rem;
  font-weight: 700;
  letter-spacing: 0.15rem;
  text-transform: uppercase;
}
.table th {
  font-size: 1.4rem;
  background-color: var(--clr-neutral-white);
  color: var(--clr-neutral-black);
  border-bottom: 1px solid var(--clr-neutral-grey-200);
}
.table td {
  transition: all 0.3s ease;
  font-size: 1.4rem;
}
.table td > .space-between {
  display: flex;
  justify-content: space-between;
}
.table td > .space-between i {
  margin-left: 0.5rem;
}
.table td a:not(.form-btn-cancel) {
  display: inline-block;
  text-decoration: underline;
  color: inherit;
  transition: all 0.3s ease;
}
.table td a:not(.form-btn-cancel):hover {
  text-decoration: none;
}
.table td i:not(h3 i) {
  font-size: 1.8rem;
  margin-left: 0.3rem;
  transition: all 0.3s ease;
}
.table td form {
  background-color: transparent;
  display: inline-block;
  width: auto;
}
.table td i, .table td form > button:not(.form-btn), .table td button:not(.form-btn) {
  cursor: pointer;
  height: auto;
  background-color: transparent;
  color: var(--clr-neutral-grey-700);
}
.table td i:hover, .table td form > button:not(.form-btn):hover, .table td button:not(.form-btn):hover {
  color: var(--clr-neutral-grey-900);
}
.table-stripe tr:nth-of-type(2n + 1) {
  background-color: var(--clr-neutral-grey-100);
  color: var(--clr-neutral-grey-700);
}
.table-stripe tr:nth-of-type(2n + 1) i, .table-stripe tr:nth-of-type(2n + 1) form > button i, .table-stripe tr:nth-of-type(2n + 1) button i {
  color: var(--clr-neutral-grey-700);
}
.table-stripe tr:nth-of-type(2n + 1) i:hover, .table-stripe tr:nth-of-type(2n + 1) form > button i:hover, .table-stripe tr:nth-of-type(2n + 1) button i:hover {
  color: var(--clr-neutral-grey-900);
}
@media only screen and (max-width: 59.875em) {
  .table th {
    display: none;
  }
  .table td {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
  }
  .table td:first-child {
    padding-top: 1rem;
  }
  .table td:last-child {
    padding-bottom: 2rem;
  }
  .table td:before {
    content: attr(data-cell) ": ";
    font-weight: 700;
    text-transform: capitalize;
  }
  .table td form {
    display: inline-block;
  }
}

.auth {
  background-color: var(--clr-primary);
}
.auth section {
  height: 100%;
  min-height: calc(100vh - 4rem);
  display: flex;
  flex-direction: column;
  padding: 5rem 1rem;
}
@media only screen and (min-width: 45em) {
  .auth section {
    align-items: center;
  }
}
@media only screen and (min-width: 60em) {
  .auth section {
    flex-direction: row;
    justify-content: space-around;
  }
}
.auth-info {
  text-align: center;
  color: var(--clr-neutral-white);
  margin-bottom: 4rem;
}
.auth-info h1.display-1 {
  color: var(--clr-neutral-white);
  margin-bottom: 1rem;
}
@media only screen and (min-width: 60em) {
  .auth-info {
    text-align: left;
  }
}
.auth-info a {
  color: var(--clr-neutral-white);
}
.auth form {
  padding: 1.5rem 3rem 3rem 3rem;
  border-radius: 1rem;
  box-shadow: 5px 7px 7px rgba(0, 0, 0, 0.5);
}
.auth-footer {
  background-color: var(--clr-primary) !important;
}
.auth-footer p {
  color: var(--clr-neutral-white) !important;
}

.user {
  position: relative;
  display: flex;
}
@media only screen and (min-width: 45em) {
  .user {
    max-width: 50rem;
  }
}
.user dl {
  margin-bottom: 2rem;
}
.user img {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  border-radius: 50%;
}
@media only screen and (min-width: 45em) {
  .user img {
    right: 3.5rem;
  }
}

.home {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--clr-neutral-grey-200);
  padding: 0.75rem 1rem;
}
@media only screen and (min-width: 60em) {
  .home {
    width: 100%;
  }
}
.home-actions {
  text-align: right;
}
.home p {
  font-size: 1.4rem;
}
.home a {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  background-color: var(--clr-info);
  color: var(--clr-neutral-white);
  border-radius: 0.5rem;
  width: 3rem;
  height: 3rem;
  transition: background-color 0.3s ease;
}
.home a:hover {
  background-color: var(--clr-info-dark);
}

.photo-album {
  margin-bottom: 1.2rem;
}
.photo-album-info {
  border-bottom: 1px solid var(--clr-neutral-grey-200);
  margin-bottom: 1rem;
  gap: 1.2rem;
}
@media only screen and (min-width: 45em) {
  .photo-album-info {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }
}
.photo-album-info span {
  text-align: right;
  min-width: 18rem;
  font-size: 1.2rem;
  font-weight: 500;
}
.photo-form {
  min-width: auto;
}
.photo-form-group {
  position: relative;
  margin-bottom: 1.5rem;
  display: flex;
}
.photo-form-group label, .photo-form-group button {
  border-radius: 0.5rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  padding: 0.2rem 1rem;
  color: var(--clr-neutral-white);
}
.photo-form-group label {
  margin-right: 0.5rem;
  background-color: var(--clr-info);
}
.photo-form-group label:hover {
  background-color: var(--clr-info-dark);
}
.photo-form-group button {
  background-color: var(--clr-success);
  color: var(--clr-neutral-white);
}
.photo-form-group button:hover {
  background-color: var(--clr-success-dark);
}

.albums {
  margin-top: 2rem;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 1rem;
  justify-content: center;
}
@media only screen and (min-width: 30em) {
  .albums {
    justify-content: flex-start;
  }
}
.albums a {
  text-decoration: none;
}

.album {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 0.5rem;
  width: 15rem;
  height: 15rem;
  background-color: var(--clr-info);
}
.album > a {
  width: 100%;
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: var(--clr-neutral-white);
  transition: background-color 0.3s ease;
}
.album > a:hover {
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  background-color: var(--clr-info-dark);
}
.album > a::before {
  text-align: center;
  vertical-align: center;
  font-family: "Font Awesome 6 Free", serif;
  content: "\f302";
  color: hsla(var(--clr-neutral-white-h), var(--clr-neutral-white-s), var(--clr-neutral-white-l), 0.3);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  font-size: 8rem;
  opacity: 0.5;
}
.album-name {
  padding: 1rem 1rem 0 1rem;
  font-size: 1.4rem;
  text-align: center;
  line-height: 1.3;
}
.album header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.2rem 1rem;
  width: 100%;
  background-color: var(--clr-info);
  color: var(--clr-neutral-white);
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  transition: background-color 0.3s ease;
}
.album header span {
  font-size: 1.2rem;
  font-weight: 500;
  color: var(--clr-neutral-white);
}
.album header:hover {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  background-color: var(--clr-info-dark);
}
.album-actions {
  display: flex;
}
.album-actions i {
  font-size: 1.8rem;
  margin-left: 0.5rem;
}
.album-actions form {
  display: inline-block;
}
.album-actions button {
  background-color: transparent;
  margin-left: 0.2rem;
}
.album-visible {
  color: var(--clr-neutral-white);
}
.album-hidden {
  color: var(--clr-neutral-grey-200);
}
.album footer {
  padding: 0.2rem 1rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 1rem;
  font-weight: 500;
}

.gallery {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 20rem;
  grid-gap: 1rem;
}
@media only screen and (min-width: 45em) {
  .gallery {
    grid-template-columns: repeat(4, 1fr);
  }
}
.gallery-item {
  position: relative;
  cursor: pointer;
  overflow: hidden;
  border-radius: 0.5rem;
}
.gallery-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: 0.3s ease-in-out;
}
.gallery-item img:hover {
  transform: scale(1.1);
}
.gallery-photo-actions {
  width: 100%;
  top: 0;
  right: 0;
  padding: 0.5rem 1rem;
  position: absolute;
  background-color: hsla(var(--clr-neutral-black-h), var(--clr-neutral-black-s), var(--clr-neutral-black-l), 0.3);
  text-align: right;
  z-index: 998;
}
.gallery-photo-actions form {
  display: inline-block;
}
.gallery-photo-actions button {
  background-color: transparent;
}
.gallery-photo-actions i {
  margin-left: 0.5rem;
  font-size: 2.2rem;
}
.gallery-photo-actions:hover {
  cursor: initial;
}
.gallery-photo-visible i {
  color: var(--clr-neutral-white);
}
.gallery-photo-hidden i {
  color: var(--clr-neutral-grey-200);
}
.gallery-modal {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: hsla(var(--clr-neutral-black-h), var(--clr-neutral-black-s), var(--clr-neutral-black-l), 0.8);
  z-index: 999;
  opacity: 0;
  pointer-events: none;
}
.gallery-modal .modal-image {
  width: 100%;
  max-height: 80vh;
  object-fit: cover;
}
@media only screen and (min-width: 45em) {
  .gallery-modal .modal-image {
    max-width: 90%;
  }
}
@media only screen and (min-width: 60em) {
  .gallery-modal .modal-image {
    max-width: 100%;
  }
}
.gallery-modal .modal-close-btn {
  color: rgba(255, 255, 255, 0.87);
  font-size: 3rem;
  position: absolute;
  top: 1rem;
  right: 2.5rem;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}
.gallery-modal .modal-close-btn:hover {
  color: var(--clr-neutral-white);
}
.gallery-modal .modal-nav {
  margin-top: 1rem;
}
.gallery-modal .modal-nav button {
  padding: 0.75rem 4.5rem;
  border: 1px solid var(--clr-neutral-white);
  background: none;
  color: var(--clr-neutral-white);
  outline: none;
  cursor: pointer;
}
.gallery-modal .modal-nav button i {
  font-size: 2rem;
}
.gallery-modal .modal-nav button:hover {
  background-color: hsla(var(--clr-neutral-white-h), var(--clr-neutral-white-s), var(--clr-neutral-white-l), 0.2);
}
.gallery-modal .modal-content {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.gallery-modal .modal-next {
  margin-left: 0.5rem;
}
.gallery-modal .modal-prev {
  margin-right: 0.5rem;
}
.gallery-modal.active {
  opacity: 1;
  pointer-events: all;
}
.gallery-modal.active .modal-image, .gallery-modal.active .modal-text {
  animation: zoom 0.3s linear;
}

.error-page {
  background-color: var(--clr-primary);
  color: var(--clr-neutral-white);
}
.error-page header {
  width: 100%;
  height: 6rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.error-page header .header-brand {
  text-decoration: none;
  font-size: 2.5rem;
  color: var(--clr-neutral-white);
}
.error-page main {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 4rem - 10rem);
}

.error-header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 136.6rem;
  margin: 0 auto;
  padding: 0 1rem;
}
.error-header-content a {
  color: var(--clr-primary-lighter);
  transition: color 0.3s ease;
}
.error-header-content a i {
  font-size: 2.5rem;
}
.error-header-content a:hover, .error-header-content a i:hover {
  color: var(--clr-neutral-white);
}
@media only screen and (min-width: 45em) {
  .error-header-content {
    padding: 0 1rem;
  }
}
@media only screen and (min-width: 60em) {
  .error-header-content {
    padding: 0 3rem;
  }
}

.footer {
  width: 100%;
  background-color: var(--clr-neutral-white);
  padding: 1rem 0;
}
.footer p {
  font-weight: 500;
  font-size: 1.2rem;
  text-align: center;
}