@use "colors" as *;

// Miscellaneous
$container-max-width: 136.6rem;
$sidebar-width: 20rem;
$sidebar-md-width: 5rem;
$not-important: !important;

// color black
$clr-black: var(--clr-neutral-black);
$clr-white: var(--clr-neutral-white);
$bg-clr-tooltip: var(--clr-neutral-grey-900);
$bg-comment: var(--clr-neutral-grey-200);

// Flash
$font-size-flash: 1.2rem !important;

// Pagination
$pagination-bg-clr: var(--clr-info);
$pagination-clr: var(--clr-neutral-white);
$pagination-active-bg-clr: var(--clr-info-darker);
$pagination-active-clr: var(--clr-neutral-white);
$pagination-border-clr: var(--clr-info);
$pagination-link-clr: var(--clr-neutral-white);
$pagination-page-of-pages-clr: var(--clr-neutral-grey-800);

// Form
$form-input-size: 1.5rem;
$form-bg-clr: var(--clr-neutral-white);
$form-input-border-clr: var(--clr-neutral-grey-200);
$form-btn-clr: var(--clr-neutral-white);
$form-btn-bg-clr: var(--clr-neutral-grey-600);
$form-btn-bg-hover-clr: var(--clr-neutral-grey-700);
$form-cancel-btn-clr: var(--clr-neutral-grey-400);
$form-cancel-btn-bg-clr: var(--clr-neutral-white);
$form-cancel-btn-hover-clr: var(--clr-neutral-grey-700);
$form-auth-xs-width: 32rem;
$form-auth-width: 40rem;

// Table
$table-bg-clr: var(--clr-neutral-white);
$table-clr: var(--clr-neutral-grey-700);
$table-border: 1px solid  var(--clr-neutral-grey-200);
$table-caption-bg-clr: var(--clr-neutral-grey-600);
$table-caption-clr: var(--clr-neutral-white);
$table-th-bg-clr: var(--clr-neutral-white);
$table-th-border-clr: var(--clr-neutral-grey-200);
$table-th-clr: var(--clr-neutral-black);
$table-button-icon-clr: $table-clr;
$table-icon-button-hover-clr: var(--clr-neutral-grey-900);
// Table striped
$table-stripe-bg-clr: var(--clr-neutral-grey-100);
$table-stripe-clr: var(--clr-neutral-grey-700);
$table-stripe-icon-button-hover-clr: var(--clr-neutral-grey-900);

// Typography
$heading-display-font-weight: 300;

$modal-bg-clr: var(--clr-neutral-black);
$modal-container-bg-clr: var(--clr-neutral-white);
$modal-container-clr: var(--clr-neutral-white);
$modal-container-box-shadow-clr: var(--clr-neutral-black);
$modal-header-border-clr: var(--clr-neutral-grey-200);
$modal-footer-border-clr: $modal-header-border-clr;
$modal-close-clr: var(--clr-neutral-black);
$modal-close-hover-clr: var(--clr-neutral-black);

// Cookie
$cookies-clr: var(--clr-neutral-white);
$cookies-bg-clr: var(--clr-neutral-black);
$cookies-link-clr: var(--clr-neutral-grey-200);
$cookies-link-hover-clr: var(--clr-neutral-white);
$cookies-btn-clr: var(--clr-neutral-grey-900);
$cookies-btn-bg-clr: var(--clr-neutral-white);
$cookies-btn-bg-hover-clr: var(--clr-neutral-grey-200);

$heading-type-scale: (
  1: 1.975rem,
  2: 1.925rem,
  3: 1.9rem,
  4: 1.875rem,
  5: 1.825rem,
  6: 1.7rem,
);

$display-type-scale: (
  1: 1.975rem,
  "1-xl": 4rem,
  2: 1.875rem,
  3: 1.8rem,
);

// Media Queries Breakpoints
$breakpoints: (
    "xs": 0,
    "sm": 480,
    "md": 720,
    "lg": 960,
    "xl": 1440,
    'xxl': 1920
);