@use '../abstracts/colors' as *;
@use '../abstracts/functions' as *;
@use "sass:list";

@each $theme, $color-array in $colors {
  $theme-selector: '[data-theme=#{$theme}]';
  @if $theme == 'default' {
    $theme-selector: ':root'; //$theme-selector: ':root, [data-theme="default"]';
  }

  #{$theme-selector} {
    @each $main-color, $variation-array in $color-array {
      @each $variation, $hsl in $variation-array {
        $appendix: #{'-'+$variation};
        @if $variation == 'base' {
          $appendix: '';
        }
        $list: get-hsl-values($hsl);

        --clr-#{$main-color}#{$appendix}-h: #{list.nth($list, 1)};
        --clr-#{$main-color}#{$appendix}-s: #{list.nth($list, 2)};
        --clr-#{$main-color}#{$appendix}-l: #{list.nth($list, 3)};
        --clr-#{$main-color}#{$appendix}: hsl(var(--clr-#{$main-color}#{$appendix}-h), var(--clr-#{$main-color}#{$appendix}-s), var(--clr-#{$main-color}#{$appendix}-l));
      }
    }
  }
}