@use "../abstracts/variables" as *;
@use "../abstracts/functions" as *;
@use "../abstracts/mixins" as *;

h1, h2, h3, h4, h5, h6 {
  font-family: inherit;
  font-weight: 500;
}

h1 {
  margin-bottom: 1rem;

  @include mq(md) {
    margin-bottom: 2.5rem;
  }
}

h2 {
  margin-bottom: 1rem;
}

h3 {
  margin-bottom: .8rem;
}

h4 {
  margin-bottom: .6rem;
}

h5 {
  margin-bottom: .4rem;
}

h6 {
  margin-bottom: .2rem;
}


@each $heading, $size in $heading-type-scale {
  @if($heading == 1) {
    $size: sass-clamp($size, $size, 4.5rem, 1.5vw)
  }

  @if($heading == 2) {
    $size: sass-clamp($size, $size, 3rem, 0.9vw)
  }

  @if($heading == 3) {
    $size: sass-clamp($size, $size, 2.6rem, 0.6vw)
  }

  @if($heading == 4) {
    $size: sass-clamp($size, $size, 2.2rem, 0.3vw)
  }

  @if($heading == 5) {
    $size: sass-clamp($size, $size, 1.9rem, 0.3vw)
  }

  @if($heading == 6) {
    $size: sass-clamp($size, $size, 1.7rem, 1.5vw)
  }

  h#{$heading} {
    font-size: $size;
  }
}

@each $display, $font-size in $display-type-scale {
  @if($display == 1) {
    $font-size: calc($font-size + 3.5vw);
  }

  @if($display == "1-xl") {
    $font-size: sass-clamp($font-size, $font-size, 11rem, 5vw);
  }

  @if($display == 2) {
    $font-size: calc($font-size + 2.5vw);
  }

  @if($display == 3) {
    $font-size: calc($font-size + 1.7vw);
  }

  .display-#{$display} {
    font-family: inherit;
    font-size: $font-size;
    font-weight: $heading-display-font-weight;
    color: var(--clr-neutral-grey-800);
  }
}

p {
  font-size: 1.6rem;
  margin-top: 0;
  letter-spacing: 0.1rem;

  &:not(:last-of-type) {
    padding-bottom: 1rem;

    @include mq(md) {
      padding-bottom: 1.5rem;
    }
  }

  &.para-lg {
    font-size: 1.6rem;

    @include mq(md) {
      font-size: 1.8rem;
    }
  }
}