@use "../abstracts/mixins" as *;
@use "../abstracts/functions" as *;
@use "../abstracts/variables" as *;

.error-page {
  background-color: var(--clr-primary);
  color: var(--clr-neutral-white);

  header {
    width: 100%;
    height: 6rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .header-brand {
      text-decoration: none;
      font-size: 2.5rem;
      color: var(--clr-neutral-white);
    }
  }

  main {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 4rem - 10rem);
  }
}

.error-header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: $container-max-width;
  margin: 0 auto;
  padding: 0 1rem;

  a {
    color: var(--clr-primary-lighter);
    transition: color 0.3s ease;

    i {
     font-size: 2.5rem;
    }

    &:hover, & i:hover {
      color: var(--clr-neutral-white);
    }
  }

  @include mq(md) {
    padding: 0 1rem;
  }

  @include mq(lg) {
    padding: 0 3rem;
  }
}