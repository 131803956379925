@use "../abstracts/mixins" as *;
@use "../abstracts/functions" as *;
@use "../abstracts/variables" as *;

.user {
  position: relative;
  display: flex;

  @include mq(md) {
    max-width: 50rem;
  }

  dl {
    margin-bottom: 2rem;
  }

  img {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    border-radius: 50%;

    @include mq(md) {
      right: 3.5rem;
    }
  }
}