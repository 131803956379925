@use "variables" as *;
@use "functions" as *;

@mixin alpha($property, $color-variable, $opacity) {
  $color-variable-h: var(#{$color-variable+'-h'});
  $color-variable-s: var(#{$color-variable+'-s'});
  $color-variable-l: var(#{$color-variable+'-l'});

  #{$property}: hsla($color-variable-h, $color-variable-s, $color-variable-l, $opacity);
}

@mixin push-auto {
  margin: {
    left: auto;
    right: auto;
  };
}

@mixin base-container {
  position: relative;
  padding: 0 1.5rem;

  @include mq('lg') {
    padding: 0 3rem;
  }
}

// Media Queries mixins
@mixin mq($key, $type: min) {
  @each $key, $value in $breakpoints {

  }
  @if map_has-key($breakpoints, $key) {
    $size: map-get($breakpoints, $key);

    @if($type == max) {
      $size: $size - 2;
    }

    @media only screen and (#{$type}-width: em($size))  {
      @content;
    }
  }
}

@mixin scrollbar-small() {
    overflow: auto;

    &::-webkit-scrollbar {
      width: 2px;
      height: 2px;
    }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
      -webkit-border-radius: 0.5rem;
      border-radius: 0.5rem;
    }

    &::-webkit-scrollbar-thumb {
      -webkit-border-radius: 1rem;
      border-radius: 10px;
      background: rgba(255, 255, 255, 0.3);
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.4);
    }

    &::-webkit-scrollbar-thumb:window-inactive {
      background: rgba(255, 255, 255, 0.3);
    }
}

@mixin scrollbar-large() {
    overflow: auto;

    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
      -webkit-border-radius: 0.5rem;
      border-radius: 0.5rem;
    }

    &::-webkit-scrollbar-thumb {
      -webkit-border-radius: 1rem;
      border-radius: 10px;
      background: rgba(255, 255, 255, 0.3);
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.4);
    }

    &::-webkit-scrollbar-thumb:window-inactive {
      background: rgba(255, 255, 255, 0.3);
    }
}