@use "../abstracts/mixins" as *;
@use "../abstracts/functions" as *;
@use "../abstracts/variables" as *;

.footer {
  width: 100%;
  background-color: var(--clr-neutral-white);
  padding: 1rem 0;

  p {
    font-weight: 500;
    font-size: 1.2rem;
    text-align: center;
  }
}