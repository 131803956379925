@use "../abstracts/mixins" as *;
@use "../abstracts/functions" as *;
@use "../abstracts/variables" as *;

.auth {
  background-color: var(--clr-primary);

  section {
    height: 100%;
    min-height: calc(100vh - 4rem);
    display: flex;
    flex-direction: column;
    padding: 5rem 1rem;

    @include mq(md) {
      align-items: center;
    }

    @include mq(lg) {
      flex-direction: row;
      justify-content: space-around;
    }
  }

  &-info {
    text-align: center;
    color: var(--clr-neutral-white);
    margin-bottom: 4rem;

    h1.display-1 {
      color: var(--clr-neutral-white);
      margin-bottom: 1rem;
    }

    @include mq(lg) {
      text-align: left;
    }

    a {
      color: var(--clr-neutral-white);
    }
  }

  form {
    padding: 1.5rem 3rem 3rem 3rem;
    border-radius: 1rem;
    box-shadow: 5px 7px 7px rgba(0,0,0,0.5);
  }

  &-footer {
    background-color: var(--clr-primary) !important;

    p {
      color: var(--clr-neutral-white) !important;
    }
  }
}