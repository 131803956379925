@use "../abstracts/mixins" as *;
@use "../abstracts/functions" as *;
@use "../abstracts/variables" as *;

.sidebar {
  width: 100%;
  position: fixed;
  bottom: 0;
  z-index: 998;
  background-color: var(--clr-primary);

  @include mq(md) {
    top: 6rem;
    bottom: -4rem;
    height: 100%;
    min-height: calc(100vh - 6rem);
    width: 5rem;
    padding: 0 1rem;
  }

  @include mq(lg) {
    width: 20rem;
    padding: 0 3rem;
  }

  nav {
    position: relative;
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 5rem;

    @include mq(md) {
      margin-top: 2rem;
      display: block;
    }

    a, button {
      background-color: transparent;
      width: 3rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      color: var(--clr-info-lighter);
      transition: all 0.3s ease;

      span {
        text-align: center;
        margin-top: 0.3rem;
        display: block;
        font-size: 1rem;
        line-height: 1;

        @include mq(md) {
          display: none;
        }

        @include mq(lg) {
          display: flex;
        }
      }

      @include mq(md) {
        margin-bottom: 1.5rem;

        i {
          font-size: 2rem;
        }
      }

      @include mq(lg) {
        width: 100%;
        flex-direction: row;
        justify-content: flex-start;
        margin-bottom: 0.5rem;
        padding: 0.5rem 0;

        i {
          width: 3rem;
          text-align: center;
        }

        span {
          margin-left: 1rem;
          font-size: 1.4rem;
        }
      }

      &:hover, & span:hover {
        color: var(--clr-neutral-white);
      }
    }

    button span {
      @include mq(lg) {
        margin-top: 0;
      }
    }

    button {
      @include mq(lg) {
        display: none;
        visibility: hidden;
      }
    }
  }
}

body#feeds .sidebar nav a#feeds,
body#user .sidebar nav a#user,
body#photo-album .sidebar nav a#photo-album,
body#dashboard .sidebar nav a#dashboard,
body#new-user .sidebar nav a#new-user,
body#users .sidebar nav a#users,
body#failed-logins .sidebar nav a#failed-logins,
body#contacts .sidebar nav a#contacts {
  color: var(--clr-neutral-white);
}