@use "../abstracts/mixins" as *;
@use "../abstracts/functions" as *;
@use "../abstracts/variables" as *;

.main-content {
  width: 100%;
  margin-top: 0.5rem;

  @include mq(md) {
    padding: 0 1rem 0 1.5rem;
  }

  @include mq(lg) {
    min-width: 50rem;
    width: 100%;
    padding: 0 0 0 3rem;
  }
}

.container:has(.menus) {
  .main-content {
    width: 100%;

    @include mq(md) {
      padding: 0 1.5rem;
    }

    @include mq(lg) {
      min-width: 50rem;
      width: 100%;
      padding: 0 3rem;
    }
  }
}

.main-content.admin {
  padding: 0 1rem;

  @include mq(md) {
    padding: 0 1rem 0 1.5rem;
  }

  @include mq(lg) {
    padding: 0 0 0 3rem;
  }
}