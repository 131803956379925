@use "../abstracts/mixins" as *;
@use "../abstracts/functions" as *;
@use "../abstracts/variables" as *;

.gallery {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 20rem;
  grid-gap: 1rem;

  @include mq(md) {
    grid-template-columns: repeat(4, 1fr);
  }

  //@include mq(lg) {
  //  grid-template-columns: repeat(4, 1fr);
  //}

  &-item {
    position: relative;
    cursor: pointer;
    overflow: hidden;
    border-radius: 0.5rem;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: 0.3s ease-in-out;

      &:hover {
        transform: scale(1.1);
      }
    }
  }

  &-photo-actions {
    width: 100%;
    top: 0;
    right: 0;
    padding: 0.5rem 1rem;
    position: absolute;
    background-color: alpha($clr-black, 0.3);
    text-align: right;
    z-index: 998;

    form {
      display: inline-block;
    }

    button {
      background-color: transparent;
    }

    i {
      margin-left: 0.5rem;
      font-size: 2.2rem;
    }

    &:hover {
      cursor: initial;
    }
  }

  &-photo-visible i {
    color: var(--clr-neutral-white);
  }

  &-photo-hidden i {
    color: var(--clr-neutral-grey-200);
  }

  &-modal {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: alpha($modal-bg-clr, 0.8);
    z-index: 999;
    opacity: 0;
    pointer-events: none;

    .modal-image {
      width: 100%;

      @include mq(md) {
        max-width: 90%;
      }

      @include mq(lg) {
        max-width: 100%;
      }

      max-height: 80vh;
      object-fit: cover;
    }

    .modal-close-btn {
      color: rgba(255, 255, 255, 0.87);
      font-size: 3rem;
      position: absolute;
      top: 1rem;
      right: 2.5rem;
      cursor: pointer;
      transition: 0.2s ease-in-out;

      &:hover {
        color: var(--clr-neutral-white);
      }
    }

    .modal-nav {
      margin-top: 1rem;

      button {
        padding: 0.75rem 4.5rem;
        border: 1px solid var(--clr-neutral-white);
        background: none;
        color: var(--clr-neutral-white);
        outline: none;
        cursor: pointer;

        & i {
          font-size: 2rem;
        }

        &:hover {
          background-color: alpha($clr-white, 0.2);
        }
      }
    }

    .modal-content {
      min-height: 100vh;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .modal-next {
      margin-left: 0.5rem;
    }

    .modal-prev {
      margin-right: 0.5rem;
    }

    &.active {
      opacity: 1;
      pointer-events: all;

      .modal-image, .modal-text {
        animation: zoom 0.3s linear;
      }
    }
  }
}

//.gallery {
//  display: grid;
//  width: 100%;
//  grid-template-columns: repeat(2, 1fr);
//  grid-gap: 1rem;
//
//  @include mq(md) {
//    grid-template-columns: repeat(3, 1fr);
//  }
//
//  &-item {
//    position: relative;
//    cursor: pointer;
//    overflow: hidden;
//    border-radius: 0.5rem;
//
//    img {
//      width: 100%;
//      height: 100%;
//      object-fit: cover;
//      transition: 0.3s ease-in-out;
//
//      &:hover {
//        transform: scale(1.1);
//      }
//    }
//
//    &:has(.gallery-photo-actions) {
//      img {
//        margin-top: 3.5rem;
//      }
//    }
//  }
//
//  &-photo-actions {
//    top: 0;
//    padding: 0.5rem 1rem;
//    width: 100%;
//    position: absolute;
//    background-color: alpha($modal-bg-clr, 0.8);
//    text-align: right;
//    z-index: 999;
//
//    i {
//      margin-left: 0.5rem;
//      font-size: 1.8rem;
//    }
//  }
//
//  &-photo-visible i {
//    color: var(--clr-neutral-white);
//  }
//
//  &-photo-hidden i {
//    color: var(--clr-neutral-grey-500);
//  }
//
//  &-close {
//    color: rgba(255, 255, 255, 0.87);
//    font-size: 2.5rem;
//    position: absolute;
//    top: 0;
//    right: 0;
//    margin: 2rem;
//    cursor: pointer;
//    transition: 0.2s ease-in-out;
//
//    &:hover {
//      color: var(--clr-neutral-white);
//    }
//  }
//
//  &-modal {
//    width: 100%;
//    height: 100%;
//    position: fixed;
//    top: 0;
//    display: flex;
//    justify-content: center;
//    align-items: center;
//    background-color: alpha($modal-bg-clr, 0.8);
//    margin-top: -1px;
//    animation: zoom 0.3s ease-in-out;
//    z-index: 999;
//
//    img {
//      width: 85%;
//      @include mq(md) {
//        width: 75%;
//      }
//
//      @include mq(lg) {
//        width: 50%;
//      }
//      max-height: 80vh;
//      object-fit: cover;
//    }
//  }
//
//  &-next, &-prev {
//    font-size: 3rem;
//    color: var(--clr-neutral-white);
//    cursor: pointer;
//  }
//
//  &-next {
//    margin-left: 0.5rem;
//  }
//
//  &-prev {
//    margin-right: 0.5rem;
//  }
//}

//.photo-album, .photos {
//  display: flex;
//  align-items: center;
//  justify-content: center;
//  flex-wrap: wrap;
//  gap: 2rem;
//
//  @include mq(md) {
//    justify-content: flex-start;
//  }
//}
//
//.photo {
//  position: relative;
//
//  header {
//    height: 3.4rem;
//    position: absolute;
//    top: 0;
//    right: 0;
//    left: 0;
//    text-align: right;
//    padding: 0.5rem 1rem;
//    background-color: alpha($clr-black, 0.7);
//    z-index: 999;
//
//    i {
//      margin-left: 0.5rem;
//      font-size: 1.8rem;
//    }
//
//    span.photo-hidden i {
//      color: var(--clr-neutral-grey-300);
//    }
//
//    span.photo-visible i {
//      color: var(--clr-neutral-white);
//    }
//  }
//
//  &-overlay {
//    display: flex;
//    justify-content: space-between;
//    flex-direction: column;
//    background-color: alpha($clr-black, 0.3);
//    position: absolute;
//    top: 0;
//    left: 0;
//    right: 0;
//    bottom: 0;
//    transition: opacity 0.3s ease;
//    cursor: pointer;
//    opacity: 0;
//    color: var(--clr-neutral-white);
//
//    footer {
//      display: flex;
//      justify-content: space-between;
//      align-items: center;
//      font-size: 1rem;
//      font-weight: 500;
//      padding: 0.5rem 1rem;
//    }
//
//    &:hover {
//      opacity: 1;
//    }
//  }
//
//  &-text {
//    margin-top: 4rem;
//    text-align: center;
//    padding: 0.5rem 1rem;
//    line-height: 1.4;
//  }
//}