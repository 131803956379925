@use "../abstracts/mixins" as *;
@use "../abstracts/functions" as *;
@use "../abstracts/variables" as *;

.pagination {
  margin-top: 5rem;
  max-width: 100%;

  &-rows,
  & ul {
    display: flex;
    width: auto;
    justify-content: center;
    align-items: center;
  }

  &-rows {
    font-size: 1.2rem;
    font-weight: bold;
  }

  &-info {
    display: flex;
    justify-content: space-between;
    margin: 0.5rem 0.5rem 0 0.5rem;
  }

  ul {

    @include mq(md) {
      margin-top: 3rem;
    }
  }

  & li {
    position: absolute;
    top: -9999px;
    left: -9999px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $pagination-bg-clr;
    color: $pagination-clr;
    border: 1px solid $pagination-border-clr;
    transition: all 0.3s ease-out;
    height: 3.6rem;
    min-width: 3.6rem;

    &:not(:last-child) {
      margin-right: .4rem;
    }

    @include mq(md) {
      position: unset;
      height: 100%
    }

    &:first-of-type,
    &.active,
    &:last-of-type,
    &:nth-of-type(2),
    &:nth-last-of-type(2) {
      position: initial;
      top: initial;
      left: initial;
    }

    &:first-child {
      border-left: 1px solid $pagination-border-clr;
      border-top-left-radius: .5rem;
      border-bottom-left-radius: .5rem;
    }

    &:last-child {
      border-top-right-radius: .5rem;
      border-bottom-right-radius: .5rem;
    }

    &.active {
      background-color: $pagination-active-bg-clr;
      color: $pagination-active-clr;
      border: 1px solid $pagination-border-clr;
    }

    &:hover {
      background-color: $pagination-active-bg-clr;

      & a {
        color: $pagination-active-clr;
      }
    }
  }

  & a,
  & span {
    display: inline-block;
    text-decoration: none;
    padding: .5rem 1rem;
  }

  & a,
  & a:active {
    color: $pagination-link-clr;
  }

  &-page-of-pages {
    margin-top: 1rem;
    font-size: 1.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $pagination-page-of-pages-clr;
  }

  &-info &-page-of-pages {
    font-size: 1.2rem;
    font-weight: bold;
    margin-top: 0;
  }
}