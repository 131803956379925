@use "../abstracts/mixins" as *;
@use "../abstracts/functions" as *;
@use "../abstracts/variables" as *;

.table {
  width: 100%;
  border-collapse: collapse;
  background-color: $table-bg-clr;
  color: $table-clr;
  border: $table-border;

  caption,
  th,
  td {
    padding: 0.75rem;
  }
  caption,
  th {
    text-align: left;
  }

  caption {
    background-color: $table-caption-bg-clr;
    color: $table-caption-clr;
    font-size: 2rem;
    font-weight: 700;
    letter-spacing: 0.15rem;
    text-transform: uppercase;
  }

  th {
    font-size: 1.4rem;
    background-color: $table-th-bg-clr;
    color: $table-th-clr;
    border-bottom: 1px solid $table-th-border-clr;
  }

  td {
    transition: all .3s ease;
    font-size: 1.4rem;

    & > .space-between {
      display: flex;
      justify-content: space-between;

      i {
        margin-left: 0.5rem;
      }
    }

    a:not(.form-btn-cancel) {
      display: inline-block;
      text-decoration: underline;
      color: inherit;
      transition: all 0.3s ease;

      &:hover {
        text-decoration: none;
      }
    }

    i:not(h3 i) {
      font-size: 1.8rem;
      margin-left: .3rem;
      transition: all .3s ease;
    }

    form {
      background-color: transparent;
      display: inline-block;
      width: auto;
    }

    i, form > button:not(.form-btn), button:not(.form-btn) {
      cursor: pointer;
      height: auto;
      background-color: transparent;
      color: $table-button-icon-clr;

      &:hover {
        color: $table-icon-button-hover-clr;
      }
    }
  }

  &-stripe {
    tr:nth-of-type(2n + 1) {
      background-color: $table-stripe-bg-clr;
      color: $table-stripe-clr;

      i, form > button i, button i {
        color: $table-stripe-clr;

        &:hover {
          color: $table-stripe-icon-button-hover-clr;
        }
      }
    }
  }

  @include mq(lg, 'max'){
    th {
      display: none;
    }
    td {
      display: grid;
      grid-template-columns: 1fr 2fr;
      gap: 0.5rem;
      padding: .5rem 1rem;

      &:first-child {
        padding-top: 1rem;
      }

      &:last-child {
        padding-bottom: 2rem;
      }

      &:before {
        content: attr(data-cell) ': ';
        font-weight: 700;
        text-transform: capitalize;
      }

      & form {
        display: inline-block;
      }
    }
  }
}