@use "../abstracts/mixins" as *;
@use "../abstracts/functions" as *;
@use "../abstracts/variables" as *;

.feed, .message {
  position: relative;
  width: 100%;
  border-bottom: 2px solid var(--clr-neutral-grey-200);
  padding: 1rem 0 0.5rem 0;

  &-auth {
    padding: 0 0 1.5rem 0;
    margin: 1rem 0 0 0;
    display: flex;

    img {
      margin-left: 1rem;
      margin-top: 0.5rem;
      border-radius: 50%;
    }

    form {
      position: relative;
      width: 100%;
      display: flex;
      margin-left: 1rem;

      textarea {
        &::-webkit-scrollbar {
          display: none;
        }
        flex: 1;
        padding: 0.2rem 1rem 0.4rem 1rem;
        border: 1px solid var(--clr-neutral-grey-200);
        border-radius: 1rem;
        resize: none;
      }

      .form-group {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .form-group-feed, .form-group-message {
        position: relative;
        width: 100%;
        display: flex;
      }

      input[type="file"] + label,
      select + label {
        padding: 0 1rem;
        font-size: 2rem;
        cursor: pointer;
      }

      input[type="file"] + label {
        color: var(--clr-success);
      }

      select {
        position: absolute;
        top: -6rem;
        right: 5rem;
        width: auto;
        opacity: 0;
        border-radius: 1rem;
        padding: 0.5rem 1rem;
        transition: 0.3s all ease;
      }

      select + label {
        color: var(--clr-info);
      }

      select.active {
        opacity: 1;
        top: 0.5rem;
      }

      button {
        background-color: transparent;
        color: var(--clr-info);;
      }
    }
  }

  header {
    padding: 0 1rem;
    display: flex;
    justify-content: space-between;
  }

  &-user {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;

    img {
      border-radius: 50%;
    }

    &-info {
      margin-left: 0.5rem;
      display: inline-block;

      span {
        display: block;
        line-height: 1.4;
        font-weight: 500;

        &:first-child {
          font-size: 1.2rem;

          i {
            font-size: 1.4rem;
          }
        }

        &:last-child {
          font-size: 0.8rem;
        }
      }
    }
  }

  &-close {
    position: absolute;
    top: -0.5rem;
    right: 0;
    color: var(--clr-neutral-grey-600);
    transition: all 0.3s;
    cursor: pointer;

    &:hover {
      color: var(--clr-neutral-grey-900);
    }
  }

  &-body {
    padding: 0.5rem 0 0 0;

    p {
      padding: 0 1rem;
      font-size: 1.4rem;
    }
  }

  &-info {
    display: flex;
    justify-content: space-between;

    a, span, button {
      text-decoration: none;
      display: block;
      padding: 0.5rem 1.2rem;
      font-size: 1.2rem;
      color: inherit;

      i {
        margin-right: 0.2rem;
        color: var(--clr-info);
      }
    }
  }

  &-image {
    margin-top: 0.5rem;
    width: 100%;
    height: auto;
    text-align: center;
    background-color: var(--clr-neutral-grey-900);
  }

  footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 1.2rem 0 1.2rem;
    border-top: 1px solid var(--clr-neutral-grey-200);
    color: var(--clr-neutral-grey-800);

    a, span {
      text-decoration: none;
      color: inherit;
      font-size: 1.2rem;

      & i {
        margin-right: 0.3rem;
      }
    }

    form, button {
      color: inherit;
      font-size: 1.2rem;

      i {
        margin-right: 0.3rem;
      }
    }
  }

  &-comments {
    height: 100%;
    max-height: 0;
    padding: 0 1rem;
    overflow: hidden;
    transition: all 0.2s ease-in-out;

    &.active {
      margin-top: 0.3rem;
      padding: 1rem 1rem 0.5rem 1rem;
      display: block;
      max-height: 50rem;
      @include scrollbar-small;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  &-comment {
    &:not(:last-child) {
      margin-bottom: 0.5em;
    }
    img {
      border-radius: 50%;
      margin-right: 0.5rem;
    }

    &-group {
      display: flex;
    }

    &-body {
      padding: 0 1rem;
      border-radius: 1rem;
      line-height: 1.3;
      background-color: alpha($bg-comment, 0.3);

      span {
        font-size: 1.2rem;
        font-weight: 500;
      }

      p {
        font-size: 1.2rem;
        padding: 0 0 0.5rem 0;
      }
    }

    &-action {
      font-size: 1.2rem;
      margin-left: 4.5rem;

      a {
        margin-right: 0.5rem;
        text-decoration: none;

        i {
          margin-right: 0.2rem;
        }
      }

      span {
        i {
          margin-right: 0.2rem;
        }
      }

      form {
        display: inline;
        margin-right: 0.5rem;
        button i {
          margin-right: 0.2rem;
        }
      }
    }

    &-auth {
      height: 100%;
      max-height: 0;
      display: flex;
      overflow: hidden;
      transition: all 0.2s ease-in-out;

      &.active {
        margin: 1rem 0 0 0;
        padding: 0 0 1rem 0;
        max-height: 20rem;
        @include scrollbar-small;
        &::-webkit-scrollbar {
          display: none;
        }
      }

      img {
        margin-left: 1rem;
        margin-top: 0.5rem;
        border-radius: 50%;
      }

      form {
        width: 100%;
        display: flex;
        margin-left: 1rem;
        margin-right: 1rem;

        textarea {
          @include scrollbar-small;
          &::-webkit-scrollbar {
            display: none;
          }
          flex: 1;
          margin-right: 1rem;
          padding: 0.2rem 1rem 0.4rem 1rem;
          border: 1px solid var(--clr-neutral-grey-200);
          border-radius: 1rem;
          resize: none;
        }

        .form-group {
          display: flex;
          flex-direction: column;
        }

        .form-group-feed, .form-group-message {
          width: 100%;
          display: flex;
        }

        input[type="file"] + label {
          padding: 0 1rem;
          font-size: 2rem;
          color: var(--clr-success);
          cursor: pointer;
        }

        button {
          background-color: transparent;
          color: var(--clr-info);;
        }
      }
    }
  }
}

.feed-comment-auth.active .emoji-container,
.message-comment-auth.active .emoji-container {
  position: absolute;
  top: auto;
  right: 4rem;
  bottom: 8rem;
}

.feed:has(.feed-comments.active),
.message:has(.message-comments.active) {
  .feed-comment-auth.active .emoji-container,
  .message-comment-auth.active .emoji-container {
    position: absolute;
    top: auto;
    right: 4rem;
    bottom: 16rem;
  }
}

.message-auth .form-group-message  .emoji-container {
  top: 9.5rem;
}