@use "../abstracts/mixins" as *;
@use "../abstracts/functions" as *;
@use "../abstracts/variables" as *;

.searchbar {
  &-container {
    background-color: var(--clr-primary);
    z-index: 1;
    width: 100%;
    position: fixed;
    top: -6rem;
    transition: all 0.3s ease-in-out;

    &.active {
      top: 6rem;
      height: auto;
    }

    &:has(.search-results.active) {
      padding-bottom: 1.5rem;
    }
  }

  @include mq(md) {
    margin: 0 0 0 4rem;
  }

  @include mq(lg) {
    margin: 0;
    padding: 0 3rem 0 $sidebar-width;
    max-width: calc($container-max-width + $sidebar-width);
  }

  form {
    height: 100%;
    position: relative;
    display: flex;
    margin: 0 1rem;
    padding: 0.5rem 0 1.5rem 0;
    align-items: center;

    @include mq(lg) {
      margin: 0;
    }

    input {
      border-radius: 1rem;
      height: 4rem;
      background-color: var(--clr-neutral-white);
      padding-right: 5rem;
      padding-left: 2rem;
    }

    button {
      position: absolute;
      top: 0.8rem;
      right: 0.5rem;
      padding: 0.6rem 1rem 0.4rem 1rem;
      background-color: transparent;

      & i {
        color: var(--clr-neutral-grey-300);
        font-size: 2rem;
      }
    }
  }

  & .search-results {
    background-color: var(--clr-neutral-white);
    border-radius: 1rem;
    display: none;
    padding: 1rem;
    margin: 0 1rem;

    @include mq(md) {
      padding: 1rem 2.5rem;
    }

    @include mq(md) {
      padding: 1rem 3rem;
    }

    @include mq(lg) {
      margin: 0;
    }

    &.active {
      display: block;
    }
  }
}

.search-result {
  background-color: var(--clr-neutral-white);

  span {
    i {
      margin-left: 0.5rem;
      font-size: 1rem;
      color: var(--clr-success);

      &.offline {
        color: var(--clr-neutral-grey-300);
      }
    }
  }
}