@use "../abstracts/mixins" as *;
@use "../abstracts/functions" as *;
@use "../abstracts/variables" as *;

.navbar {
  list-style-type: none;
  display: flex;
  align-items: center;
  line-height: 1.4;

  a, i, form button, form button i {
    color: var(--clr-primary-lighter);
    margin: 0 0.4rem;
    transition: all 0.3s ease;

    &:hover, &.active {
      color: var(--clr-neutral-white);
    }
  }

  a {
    position: relative;
    text-decoration: none;
    font-size: 2.2rem;
  }

  i, form button i {
    font-size: 2.5rem;
  }

  form button {
    background-color: transparent;
  }

  &-message {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 2rem;
    height: 2rem;
    top: -0.4rem;
    left: 2rem;
    font-size: 1.2rem;
    font-weight: 500;
    border-radius: 50%;
    background-color: var(--clr-success);
    color: var(--clr-neutral-white);
  }

  &-btn {
    padding: 0.4rem 1.5rem;
    border-radius: 10.5rem;
    margin-left: 1rem;
    font-size: 1.6rem !important;
    transition: all 0.3s ease;
    border: 1px solid var(--clr-neutral-white);

    & button {
      color: var(--clr-neutral-white) !important;
    }

    &:hover {
      background-color: var(--clr-info-darker);
    }
  }
}

body#home  a#home i,
body#messages a#messages i{
  color: var(--clr-neutral-white);
}