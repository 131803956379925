@use "../abstracts/mixins" as *;
@use "../abstracts/functions" as *;
@use "../abstracts/variables" as *;

.header {
  position: fixed;
  width: 100%;
  background-color: var(--clr-primary);
  z-index: 999;

  &-brand {
    text-decoration: none;
    font-size: 2.5rem;
    color: var(--clr-neutral-white);

    span {
      color: var(--clr-neutral-grey-800);
    }
  }
}