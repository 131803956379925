@use "../abstracts/mixins" as *;
@use "../abstracts/functions" as *;
@use "../abstracts/variables" as *;


.friends {
  width: 100%;
  padding: 0 1rem;

  @include mq(md) {
    padding: 0 1.5rem;
  }

  @include mq(lg) {
    width: 100%;
    min-width: 50rem;
    padding: 0 3rem;
  }
}

.friend {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--clr-neutral-grey-200);


  @include mq(lg) {
    width: 100%;
    min-width: 50rem;
  }

  &-content {
    padding: 0.75rem 0 0.75rem 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
      border-radius: 50%;
    }
  }

  &-info {
    margin-left: 0.75rem;

    span {
      display: block;
      font-size: 1rem;
      font-weight: 500;
      line-height: 1.2;

      &.status {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 5.2rem;

        i {
          margin-left: 0.5rem;
          font-size: 1rem;
          color: var(--clr-success);

          &.offline {
            color: var(--clr-neutral-grey-300);
          }
        }
      }
    }
  }

  &-actions {
    display: flex;
    gap: 1rem;
    margin-right: 1rem;
  }

  &-request {
    position: relative;

    &::after {
      position: absolute;
      top: 0;
      right: 1.45rem;
      height: 0.85rem;
      width: 0.85rem;

      @include mq(md) {
        top: -0.1;
        right: 1.4rem;
        height: 1.1rem;
        width: 1.1rem;
      }
      border-radius: 50%;
      content: '';
      background-color: var(--clr-success);
    }
  }
}