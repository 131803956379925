@use "../abstracts/colors" as *;
@use "../abstracts/variables" as *;
@use "sass:map";

@each $theme, $color-array in $colors {
  $theme-selector: '[data-theme=#{$theme}]';
  @if $theme == 'default' {
    $theme-selector: ''; //$theme-selector: ':root, [data-theme="default"]';
  }

  @each $main-color, $variation-array in $color-array {
    @each $variation, $hsl in $variation-array {
      $appendix: #{'-'+$variation};

      @if $variation == 'base' {
        $appendix: '';
      }

      @if($appendix == '') {
        .text-#{$main-color} {
          color: var(--clr-#{$main-color}) #{$not-important};
        }

        .text-#{$main-color}-hover:hover, .text-#{$main-color}.active {
          color: var(--clr-#{$main-color}-dark) #{$not-important};
        }

        .bg-#{$main-color} {
          background-color: var(--clr-#{$main-color}) #{$not-important};
        }

        .bg-#{$main-color}-hover:hover, .bg-#{$main-color}.active {
          background-color: var(--clr-#{$main-color}-dark) #{$not-important};
        }

        .border-#{$main-color} {
          border-color: var(--clr-#{$main-color}) #{$not-important};
        }

        .btn-#{$main-color} {
          color: var(--clr-neutral-white) #{$not-important};
          background-color: var(--clr-#{$main-color}) #{$not-important};
          border: 1px solid var(--clr-#{$main-color}) #{$not-important};

          &:hover {
            background-color: var(--clr-#{$main-color}-dark) #{$not-important};
            border: 1px solid var(--clr-#{$main-color}-dark) #{$not-important};
          }
        }
      }

      //@debug $main-color, $appendix,;

      @if($appendix == '-white') {
        .text-white {
          color: var(--clr-neutral-white) #{$not-important};
        }

        .text-white-hover:hover, .text-white.active {
          color: var(--clr-neutral-grey-200) #{$not-important};
        }

        .text-disabled {
          color: var(--clr-neutral-grey-300) !important;
        }

        .bg-disabled {
          background-color: var(--clr-neutral-grey-100) !important;
        }

        .bg-white {
          background-color: var(--clr-neutral-white) #{$not-important};
        }

        .bg-white-hover:hover, .bg-white.active {
          color: var(--clr-neutral-grey-200) #{$not-important};
        }

        .btn-light {
          color: var(--clr-neutral-grey-700) #{$not-important};
          background-color: var(--clr-neutral-grey-100) #{$not-important};
          border: 1px solid var(--clr-neutral-grey-100) #{$not-important};

          &:hover {
            background-color: var(--clr-neutral-grey-200) #{$not-important};
            border: 1px solid var(--clr-neutral-grey-200) #{$not-important};
          }
        }

        .btn-disabled {
          color: var(--clr-neutral-grey-300) !important;
          border: 1px solid var(--clr-neutral-grey-300) !important;
          background-color: var(--clr-neutral-grey-100) !important;
          cursor: initial;
        }

        .btn-cancel {
          background-color: $form-cancel-btn-bg-clr !important;
          color: $form-cancel-btn-clr !important;
          border: 1px solid $form-cancel-btn-clr !important;

          &:hover {
            color: $form-cancel-btn-hover-clr !important;
            border: 1px solid $form-cancel-btn-hover-clr !important;
          }
        }
      }

      @if($appendix == '-grey-700') {
        .text-dark {
          color: var(--clr-neutral-grey-700) #{$not-important};
        }

        .text-dark-hover:hover, .text-dark.active {
          color: var(--clr-neutral-grey-800) #{$not-important};
        }

        .bg-dark {
          background-color: var(--clr-neutral-grey-700) #{$not-important};
        }

        .bg-dark-hover:hover, .bg-dark.active {
          background-color: var(--clr-neutral-grey-800) #{$not-important};
        }

        .btn-dark {
          color: var(--clr-neutral-white) #{$not-important};
          background-color: var(--clr-neutral-grey-700) #{$not-important};
          border: 1px solid var(--clr-neutral-grey-700) #{$not-important};

          &:hover {
            background-color: var(--clr-neutral-grey-800) #{$not-important};
            border: 1px solid var(--clr-neutral-grey-800) #{$not-important};
          }
        }
      }

     //.text-#{$main-color}#{$appendix}-hover:hover, .text-#{$main-color}#{$appendix}.active  {
     //   color: var(--clr-#{$main-color}#{$appendix}) #{$not-important};
     // }
     //
     //.bg-#{$main-color}#{$appendix}-hover:hover, .text-#{$main-color}#{$appendix}.active {
     //   background-color: var(--clr-#{$main-color}#{$appendix}) #{$not-important};
     // }
    }
  }
}