@use "../abstracts/mixins" as *;
@use "../abstracts/functions" as *;
@use "../abstracts/variables" as *;

.home {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--clr-neutral-grey-200);
  padding: 0.75rem 1rem;

  @include mq(lg) {
    width: 100%;
  }

  &-actions {
    text-align: right;
  }

  p {
    font-size: 1.4rem;
  }

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    background-color: var(--clr-info);
    color: var(--clr-neutral-white);
    border-radius: 0.5rem;
    width: 3rem;
    height: 3rem;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: var(--clr-info-dark);
    }
  }
}