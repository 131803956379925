@use "../abstracts/mixins" as *;
@use "../abstracts/functions" as *;
@use "../abstracts/variables" as *;

.tooltip {
  position: relative;
  cursor: help;
  background-image: linear-gradient(to right, var(--clr-neutral-grey-200) 33%, rgba(255,255,255,0) 0%);
  background-position: bottom;
  background-size: 4px 1px;
  background-repeat: repeat-x;

  &::after {
    background-color: $bg-clr-tooltip;
    border-radius: 1rem;
    color: var(--clr-neutral-white);
    display: none;
    padding: 0.6rem 1rem;
    position: absolute;
    text-align: center;
    z-index: 999;
    width: auto;
    font-size: 1.2rem;
    line-height: 1.2;
  }

  &-top::after {
    width: 100%;
    max-width: 20rem;
    content: attr(data-text);
    top: 0;
    left: 50%;
    transform: translate(-50%, calc(-100% - 10px));
  }

  &-bottom::after {
    width: 100%;
    max-width: 20rem;
    content: attr(data-text);
    bottom: 0;
    left: 50%;
    transform: translate(-50%, calc(100% + 10px));
  }

  &-right::after {
    content: attr(data-text);
    top: -0.75rem;
    right: 0;
    transform: translateX(calc(100% + 10px));
  }

  &-left::after {
    content: attr(data-text);
    top: -0.75rem;
    left: 0;
    transform: translateX(calc(-100% - 10px));
  }

  &:hover::after {
    display: block;
  }

  &::before {
    background-color: $bg-clr-tooltip;
    content: ' ';
    display: none;
    position: absolute;
    width: 1.5rem;
    height: 1.5rem;
    z-index: 999;
  }

  &-top::before {
    top: 0;
    left: 50%;
    transform: translate(-50%, calc(-100% - 0.5rem)) rotate(45deg);
  }

  &-bottom::before {
    bottom: 0;
    left: 50%;
    transform: translate(-50%, calc(100% + 0.5rem)) rotate(45deg);
  }

  &-right::before {
    top: -0.15rem;
    right: -1.2rem;
    width: 0.75rem;
    aspect-ratio: 1;
    clip-path: polygon(100% 100%, 100% 0, 0 50%);
  }

  &-left::before {
    top: -0.15rem;
    left: -1.2rem;
    width: 0.75rem;
    aspect-ratio: 1;
    clip-path: polygon(0 0, 100% 50%, 0 100%);
  }

  &:hover::before, &:hover::after {
    display: block;

  }
}

a > .tooltip, button > .tooltip {
  cursor: pointer;
  background-image: none;
  background-position: 0;
  background-size: 0;
  background-repeat: no-repeat;
}