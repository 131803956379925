@use "../abstracts/mixins" as *;
@use "../abstracts/functions" as *;
@use "../abstracts/variables" as *;

.description-list {
  width: 100%;
  font-size: 1.4rem;

  & dt {
    font-weight: 600;
    padding: 0 1rem 0 .5rem ;
  }

  & dd {
    margin-inline-start: 0;

    @include mq(md) {
      margin-right: 3rem;
    }

    padding: 0 1rem .5rem .5rem;
    border-bottom: 1px solid var(--clr-neutral-grey-200);
  }
}